import { useLocation } from "react-router-dom";
import {GlobalState} from "../state/GlobalState";
import {useEffect} from "react";

const WithMainLayoutPage = (WrappedComponent) => function Component(props) {
    const location = useLocation();
    const keepMessages = location?.state?.keepMessages;

    useEffect(() => {
        if (!keepMessages) {
            GlobalState.clearMessages();
        }
    });

    return (
        <WrappedComponent {...props} />
    );
};

export default WithMainLayoutPage;
