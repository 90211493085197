import {useEffect, useState} from "react";

const DataTableTextFilter = ({column}) => {
    const [fieldValue, setFieldValue] = useState(column.filterValue || '');

    useEffect(() => {
        setFieldValue(column.filterValue || '');
    }, [column.filterValue]);

    const onKeyDown = e => {
        if (e.key === 'Enter') {
            column.setFilter(fieldValue);
        }
    };

    const onBlur = () => {
        column.setFilter(fieldValue);
    };

    return (
        <input
            className="form-control form-control-sm"
            value={fieldValue}
            onClick={e => e.stopPropagation()}
            onChange={e => setFieldValue(e.target.value)}
            onKeyDown={onKeyDown}
            onBlur={onBlur}
            data-testid={`filter-${column.id}`}
            placeholder={`Search ${column.Header}...`}
        />
    );
};

export default DataTableTextFilter;
