const DataTablePerPage = ({ perPage, allowedPerPage, setPerPage }) => {
    let perPageValues = allowedPerPage || [10, 25, 50];
    let perPageOptions = [];

    perPageValues.forEach(value =>
        perPageOptions.push(
            <option key={value}>{value}</option>
        )
    );

    return (
        <>
            <span className="mr-1">Show</span>
            <select
                className="form-control per-page-dropdown"
                value={perPage}
                onChange={e => setPerPage(parseInt(e.target.value))}
            >
                {perPageOptions}
            </select>
            <span className="ml-1">rows</span>
        </>
    )
};

export default DataTablePerPage;
