import {
    closestCenter,
    DndContext,
    KeyboardSensor,
    MouseSensor,
    TouchSensor,
    useSensor,
    useSensors
} from "@dnd-kit/core";
import {arrayMove, SortableContext, verticalListSortingStrategy} from "@dnd-kit/sortable";
import {useEffect, useState} from "react";

const Sortable = ({items, itemRenderFn, onChange}) => {
    const [itemsList, setItemsList] = useState([]);

    useEffect(() => setItemsList(items), [items]);

    const sensors = useSensors(
        useSensor(MouseSensor),
        useSensor(TouchSensor),
        useSensor(KeyboardSensor),
    );

    const handleDragEnd = event => {
        const {active, over} = event;

        if (active.id !== over.id) {
            setItemsList(items => {
                const oldIndex = items.findIndex(item => item.id === active.id);
                const newIndex = items.findIndex(item => item.id === over.id);
                const sortedItems = arrayMove(items, oldIndex, newIndex);
                onChange(sortedItems);
                return sortedItems;
            });
        }
    };

    return (
        <DndContext
            sensors={sensors}
            collisionDetection={closestCenter}
            onDragEnd={handleDragEnd}
        >
            <SortableContext
                items={itemsList}
                strategy={verticalListSortingStrategy}
            >
                {itemsList.map(item => itemRenderFn(item))}
            </SortableContext>
        </DndContext>
    )
}

export default Sortable;
