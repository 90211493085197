const DataTablePagination = ({currentPage, totalPages, setPage}) => {
    const linkClass = (page) => page === currentPage ? 'page-item active' : 'page-item';

    const pageLink = (page) => {
        let link = page === currentPage
            ? (<span className="page-link">{page}</span>)
            : (<span onClick={() => setPage(page)} className="page-link">{page}</span>);

        return <li key={page} className={linkClass(page)}>{link}</li>;
    };

    const previousPageLink = currentPage < 2 ? '' : (
        <li className="page-item">
            <span onClick={() => setPage(currentPage - 1)} className="page-link" aria-label="Previous">
                <span aria-hidden="true">&larr;</span>
                <span className="sr-only">Previous</span>
            </span>
        </li>
    );

    const previousSeparator = currentPage < 5 ? '' : (
        <li className="page-item disabled"><span className="page-link">...</span></li>
    );

    let delta = 2;
    let minPage = Math.max(currentPage - delta, 2);
    let maxPage = Math.min(currentPage + delta, totalPages - 1);

    let middlePages = [];

    for (let i = minPage; i <= maxPage; i++) {
        middlePages.push(pageLink(i))
    }

    const nextSeparator = (currentPage > totalPages - 4) ? '' : (
        <li className="page-item disabled"><span className="page-link">...</span></li>
    );

    const nextPageLink = currentPage >= totalPages ? '' : (
        <li className="page-item">
            <span onClick={() => setPage(currentPage + 1)} className="page-link" aria-label="Next">
                <span aria-hidden="true">&rarr;</span>
                <span className="sr-only">Next</span>
            </span>
        </li>
    );

    const lastPageLink = totalPages > 1 ? pageLink(totalPages) : '';

    return (
        <nav aria-label="Page navigation example" className="float-right">
            <ul className="pagination">
                {previousPageLink}
                {pageLink(1)}
                {previousSeparator}
                {middlePages}
                {nextSeparator}
                {lastPageLink}
                {nextPageLink}
            </ul>
        </nav>
    );
};

export default DataTablePagination;
