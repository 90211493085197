import {action, autorun, makeObservable, observable, toJS} from "mobx";
import {BrowserStorage} from "../BrowserStorage";

class AuthenticationState
{
    constructor(state = null) {
        this.isAuthenticated = false;
        this.accessToken = null;
        this.authenticatedUser = null;

        if (state) {
            for (const [key, value] of Object.entries(state)) {
                this[key] = value;
            }
        }

        makeObservable(
            this,
            {
                isAuthenticated: observable,
                accessToken: observable,
                authenticatedUser: observable,
                logout: action,
                setAccessToken: action
            }
        )
    }

    setAccessToken(token) {
        console.log('[AuthenticationState] Setting access token, user is now logged in.');
        this.isAuthenticated = true;
        this.accessToken = token;
    }

    logout() {
        console.log('[AuthenticationState] Logout.');
        this.isAuthenticated = false;
        this.accessToken = null;
        this.authenticatedUser = null;
    }
}

const storedState = BrowserStorage.get('authentication-state');
const state = new AuthenticationState(storedState);

autorun(() => {
    console.log('[AuthenticationState] Storing in local storage.');

    BrowserStorage.set('authentication-state', toJS(state), 3600);
});

export {state as AuthenticationState};