import {useEffect, useState} from "react";
import {FileApi} from "HtvApi/Api";
import {uriToId} from "helpers/functions";

const AuthorizedImage = ({
    fileId,
    maxWidth = 1024,
    maxHeight = 768,
    testId = 'authorized-image'
}) => {
    const [source, setSource] = useState(null);

    useEffect(() => {
        FileApi
            .filePreview({id: uriToId(fileId)}, {responseType: 'blob'})
            .then(response => setSource(URL.createObjectURL(response.data)));
    }, [fileId]);

    return <img
        alt="preview"
        src={source}
        style={{maxWidth: maxWidth + 'px', maxHeight: maxHeight + 'px'}}
        data-testid={testId}
    />
};

export default AuthorizedImage;
