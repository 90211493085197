import PageCard from "../../layouts/main/components/PageCard";
import {useEffect} from "react";
import BannerZoneList from "./BannerZoneList";
import {GlobalState} from "../../state/GlobalState";
import {Link} from "react-router-dom";
import WithMainLayoutPage from "../WithMainLayoutPage";

const BannerZonePage = () => {
    useEffect(() => {
        GlobalState.setPageTitle('Banner management');
        GlobalState.setLayoutPageTitle('Banner management');
    }, []);

    return <PageCard
        title={'Banner zones'}
        actions={
            <Link to="/banners/create-zone" className="btn btn-sm btn-success">
                <i className="fas fa-plus" />&nbsp;&nbsp;&nbsp;Add new banner zone
            </Link>
        }
    >
        <BannerZoneList />
    </PageCard>
};

export default WithMainLayoutPage(BannerZonePage);