import {GlobalState} from "../../../state/GlobalState";
import {Link} from "react-router-dom";
import {observer} from "mobx-react";

const PageHeader = observer(() => {
    return (
        <div className="content-header">
            <div className="container-fluid">
                <div className="row mb-2">
                    <div className="col-sm-6">
                        <h1 className="m-0">{GlobalState.layoutPageTitle}</h1>
                    </div>
                    <div className="col-sm-6">
                        <ol className="breadcrumb float-sm-right">
                            <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                            <li className="breadcrumb-item active">Starter Page</li>
                        </ol>
                    </div>
                </div>
            </div>
        </div>
    )
});

export default PageHeader;