import Loader from "./Loader";

export default function PageCard({children, title, actions = '', loading = false}) {
    return (
        <div className="row">
            <div className="col-12">
                <div className="card">
                    <div className="card-header">
                        <h3 className="card-title text-lg">{title}</h3>
                        <div className="card-tools">
                            {actions}
                        </div>
                    </div>
                    <div className="card-body">
                        {children}
                    </div>
                    {loading ? <Loader /> : ''}
                </div>
            </div>
        </div>
    )
}
