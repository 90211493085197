import WithMainLayoutPage from "../../WithMainLayoutPage";
import PageCard from "../../../layouts/main/components/PageCard";
import Nestable from "react-nestable";
import {useEffect, useState} from "react";
import HtvApi from "../../../htv-api/HtvApi";
import {ErrorHandling} from "../../../helpers/error-handling";
import {GlobalState} from "../../../state/GlobalState";
import {Link} from "react-router-dom";
import './NewsCategoriesPage.css';
import NewsCategoryDeleteButton from "./NewsCategoryDeleteDialogButton";

const parseCategories = (categories) => {
    let nestedCategories = [];

    categories.forEach(category => {
        let item = {
            id: category.id,
            text: category.title,
            enabled: category.status === 'active',
            slug: category.slug
        };

        nestedCategories.push(item);
    });

    return nestedCategories;
};

const NewsCategoriesPage = () => {
    useEffect(() => {
        GlobalState.setPageTitle('News management');
        GlobalState.setLayoutPageTitle('News management');
    }, []);

    const [categories, setCategories] = useState([]);
    const [dataVersion, setDataVersion] = useState(1);

    useEffect(() => {
        HtvApi.NewsCategories.List().then(response => {
            setCategories(parseCategories(response['hydra:member']));
        }).catch(ErrorHandling.GlobalStateError);
    }, [dataVersion]);

    const saveCategoriesOrder = () => {
        const requestData = categories.map(category => ({id: category.id}));

        HtvApi.NewsCategories.Reorder(requestData).then(() => {
            GlobalState.setSuccess('Successful operation!', 'News positions saved!');
        }).catch(ErrorHandling.GlobalStateError);
    };

    const renderItem = ({ item }) => {
        return (
            <div className={'news-category-item ' + (item.enabled ? 'category-enabled' : 'category-disabled')}>
                <span>{item.text}</span>
                <span className={'news-category-slug ml-3 text-sm text-gray'}>{item.slug}</span>
                <NewsCategoryDeleteButton
                    id={item.id}
                    onConfirm={() => setDataVersion(version => version + 1)}
                />
                <Link to={'/news/categories/edit/' + item.id} className="btn btn-xs btn-warning float-right mr-2">
                    <i className="fas fa-pencil-alt" /> Edit
                </Link>
            </div>
        );
    }

    const actions = (
        <>
            <Link to="/news/categories/create" className="btn btn-sm btn-success">
                <i className="fas fa-plus" />&nbsp;&nbsp;&nbsp;Create new
            </Link>
            <button className="btn btn-sm btn-primary ml-2" onClick={() => saveCategoriesOrder()}>
                <i className="fas fa-save" />&nbsp;&nbsp;&nbsp;Save positions
            </button>
        </>
    );

    return (
        <PageCard title='News categories' actions={actions}>
            <Nestable
                items={categories}
                renderItem={renderItem}
                onChange={(data) => { setCategories(data.items) }}
                maxDepth={1}
            />
        </PageCard>
    );
}

export default WithMainLayoutPage(NewsCategoriesPage);
