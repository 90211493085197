import React, {useCallback, useRef, useState} from "react";
import './Form.css';

const Form = ({id, onSubmit, validation, initialData, children}) => {
    const data = useRef(Object.assign({}, initialData));
    const onChange = useCallback((value, name) => data.current[name] = value, []);
    const [errors, setErrors] = useState({});

    return (
        <form
            id={id}
            className={`form-horizontal` + Object.keys(errors).length ? ' invalid' : ''}
            onSubmit={event => {
                event.preventDefault();
                setErrors({});

                validation.validate(data.current, {abortEarly: false})
                    .then(validValues => {
                        onSubmit(validValues);
                    })
                    .catch(error => {
                        let fieldErrors = {};
                        error.inner.forEach(error => {
                            fieldErrors[error.path] = fieldErrors[error.path] || [];
                            fieldErrors[error.path].push(error.message);
                        });
                        setErrors(fieldErrors);
                    });
            }}
        >
            {children({onChange, errors})}
        </form>
    )
};

export default Form;
