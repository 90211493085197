export default class NewsCategories
{
    constructor(htvApi) {
        this.htvApi = htvApi;
    }

    Get(id) {
        return this.htvApi.Call(this.htvApi.url + '/news-categories/' + id);
    }

    List() {
        return this
            .htvApi
            .Call(this.htvApi.url + '/news-categories');
    }

    Reorder(data) {
        return this.htvApi.Call(
            this.htvApi.url + '/news-categories/reorder',
            {
                method: 'POST',
                body: JSON.stringify(data),
            }
        );
    }

    Create(data) {
        let requestData = {
            titleTranslations: [],
            slug: data.slug,
            status: data.status,
        };

        for (const [locale, title] of Object.entries(data.titleTranslations)) {
            requestData.titleTranslations.push({locale: locale, value: title});
        }

        return this.htvApi.Call(
            this.htvApi.url + '/news-categories',
            {
                method: 'POST',
                body: JSON.stringify(requestData),
            }
        );
    }

    Edit(id, data) {
        let requestData = {
            titleTranslations: [],
            slug: data.slug,
            status: data.status,
        };

        for (const [locale, title] of Object.entries(data.titleTranslations)) {
            requestData.titleTranslations.push({locale: locale, value: title});
        }

        return this.htvApi.Call(
            this.htvApi.url + '/news-categories/' + id,
            {
                method: 'PATCH',
                body: JSON.stringify(requestData),
                contentType: 'application/merge-patch+json',
            }
        );
    }

    Delete(id) {
        return this.htvApi.Call(this.htvApi.url + '/news-categories/' + id, {method: 'DELETE'});
    }
}
