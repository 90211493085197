import HtvApi from "../../../htv-api/HtvApi";

const ResolvedVideoTile = ({id, videoData}) => {
    if (!videoData.loaded) {
        return (
            <div className="row">
                <div className="col-12">
                    <div className="card card-info">
                        <div className="card-header">
                            <h3 className="card-title">Loading video...</h3>
                        </div>
                        <div className="card-body" />
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div className="row">
            <div className="col-12">
                <div className="card card-info">
                    <div className="card-header">
                        <h3 className="card-title">ID: {id}</h3>
                    </div>
                    <div className="card-body">
                        <div className="row">
                            <img
                                className="col-3"
                                style={{alignSelf: 'start'}}
                                src={HtvApi.Videos.ThumbnailUrl(id, 400, 224)}
                                alt="Video thumbnail"
                            />
                            <div className="col-9">
                                <h4>{videoData.title}</h4>
                                <p>{videoData.description}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ResolvedVideoTile;
