import HtvApiError from "../htv-api/Error";
import {GlobalState} from "../state/GlobalState";
import {logout} from "./authentication";

export class ErrorHandling {
    static GlobalStateError(error) {
        if (error.name === 'AxiosError') {
            if (error.response.status === 401) {
                GlobalState.setError('Unauthorized', 'You are not logged in, please log in to continue');
                logout();
                return;
            }

            if (error.response.data['hydra:title']) {
                GlobalState.setError(error.response.data['hydra:title'], error.response.data['hydra:description']);
                return;
            }
        }
        if (error instanceof HtvApiError) {
            GlobalState.setError(error.title, error.description);
            return;
        }
        GlobalState.setError(error.message);
    }
}
