import {useCallback, useState} from "react";
import uniqueId from "../../../../helpers/unique-id";

const FormTextField = ({
    name,
    label,
    initialValue = '',
    onChange,
    placeholder = '',
    disabled = false,
    testId = null,
    errors = null
}) => {
    const [id] = useState(uniqueId());
    const [value, setValue] = useState(initialValue);
    const onChangeFn = useCallback(e => {
        setValue(e.target.value);
        onChange(e.target.value, name);
    }, [onChange, name])

    let className = 'form-control';

    if (errors && errors.length) {
        className += ' is-invalid';
    }

    return (
        <div className="form-group row">
            <label
                htmlFor={id}
                className="col-sm-2 col-form-label"
            >
                {label}:
            </label>
            <div className="col-sm-10">
                <input
                    name={name}
                    type="text"
                    className={className}
                    id={id}
                    disabled={disabled}
                    placeholder={placeholder}
                    autoComplete="off"
                    value={value}
                    onChange={onChangeFn}
                    data-testid={testId}
                />
                <div className="invalid-feedback">
                    {errors ? errors.map((error, index) => <div key={index}>{error}</div>) : ''}
                </div>
            </div>
        </div>
    )
};

export default FormTextField;
