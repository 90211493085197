import WithMainLayoutPage from "../../WithMainLayoutPage";
import {useEffect, useRef, useState} from "react";
import {GlobalState} from "../../../state/GlobalState";
import PageCard from "../../../layouts/main/components/PageCard";
import HtvApi from "../../../htv-api/HtvApi";
import {ErrorHandling} from "../../../helpers/error-handling";
import RecommendedVideoPicker from "./RecommendedVideoPicker";
import {Button, Grid} from "@mui/material";
import ClearRoundedIcon from '@mui/icons-material/ClearRounded';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import SaveRoundedIcon from '@mui/icons-material/SaveRounded';
import {nanoid} from "nanoid";
import {Map, Record} from "immutable";
import {PreferenceApi} from "../../../HtvApi/Api";

const VideoRecommendedPage = () => {
    useEffect(() => {
        GlobalState.setPageTitle('Videos management');
        GlobalState.setLayoutPageTitle('Videos management');
    }, []);

    const [state, setState] = useState(Record({list: Map(), isLoading: true})());
    const recommended = useRef(Map());

    const saveRecommendations = () => {
        setState(state => state.set('isLoading', true));

        for (const [, value] of recommended.current) {
            if (!value) {
                GlobalState.setError('Please fill in all fields');
                setState(state => state.set('isLoading', false));
                return;
            }
        }

        PreferenceApi
            .preferenceSet({
                name: 'recommended-videos',
                preferenceData: {name: 'recommended-videos', value: JSON.stringify(recommended.current.toList().toArray())}
            })
            .then(() => GlobalState.setSuccess('Recommended videos saved!'))
            .catch(ErrorHandling.GlobalStateError)
            .then(() => setState(state => state.set('isLoading', false)))
        ;
    };

    useEffect(() => {
        HtvApi.Preferences.Get('recommended-videos').then(response => {
            let newList = Map();

            JSON.parse(response.value).forEach(id => newList = newList.set(nanoid(), id));

            recommended.current = newList;
            setState(state => state.set('list', newList).set('isLoading', false));
        }).catch(ErrorHandling.GlobalStateError)
    }, []);

    let listItems = [];

    state.list.forEach((videoId, index) => {
        listItems.push(
            <Grid container key={index} sx={{mb: 2}}>
                <Grid item xs>
                    <RecommendedVideoPicker
                        videoId={videoId}
                        key={index}
                        onChange={value => recommended.current = recommended.current.set(index, value)}
                    />
                </Grid>
                <Grid item xs="auto">
                    <Button
                        variant="contained"
                        color="error"
                        size="large"
                        startIcon={<ClearRoundedIcon />}
                        sx={{m: 1}}
                        onClick={() => {
                            recommended.current = recommended.current.delete(index);
                            setState(state => state.set('list', state.list.delete(index)));
                        }}
                        data-testid="remove-recommended-video-button"
                    >
                        Remove
                    </Button>
                </Grid>
            </Grid>
        )
    });


    return <PageCard
        title={'Manage recommended videos'}
        loading={state.isLoading}
        actions={[]}
    >
        {listItems}
        <Grid container>
            <Grid item xs />
            <Grid item xs="auto">
                <Button
                    variant="contained"
                    color="primary"
                    size="large"
                    startIcon={<AddRoundedIcon />}
                    sx={{m: 1}}
                    onClick={() => {
                        let uid = nanoid();
                        recommended.current = recommended.current.set(uid, null);
                        setState(state => state.set('list', state.list.set(uid, null)));
                    }}
                    data-testid="add-recommended-video-button"
                >
                    Add
                </Button>
            </Grid>
        </Grid>
        <Grid container>
            <Grid item xs />
            <Grid item xs="auto">
                <Button
                    variant="contained"
                    color="success"
                    size="large"
                    startIcon={<SaveRoundedIcon />}
                    onClick={saveRecommendations}
                    data-testid="save-recommended-video-button"
                >
                    Save recommendations
                </Button>
            </Grid>
            <Grid item xs />
        </Grid>
    </PageCard>
};

export default WithMainLayoutPage(VideoRecommendedPage);
