import {useState} from "react";
import HtvApi from "../../../htv-api/HtvApi";
import {GlobalState} from "../../../state/GlobalState";
import {ErrorHandling} from "../../../helpers/error-handling";
import ConfirmDeletionDialog from "../../../layouts/main/components/ConfirmDeletionDialog";

const NewsCategoryDeleteButton = ({id, onConfirm}) => {
    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState(null);

    const closeDialog = () => {
        setIsLoading(false);
        setData(null);
    };

    const confirmDelete = () => {
        HtvApi.NewsCategories.Delete(id).then(() => {
            GlobalState.setSuccess('Successful operation!', 'News category deleted successfully!');
            closeDialog();
            onConfirm && onConfirm();
        }).catch(ErrorHandling.GlobalStateError);
    };

    const showConfirmDialog = (e) => {
        e.preventDefault();
        setIsLoading(true);

        HtvApi.NewsCategories.Get(id).then(response => {
            setData({
                title: response.title,
            });
            setIsLoading(false);
        }).catch(error => {
            ErrorHandling.GlobalStateError(error);
            closeDialog();
        });
    };

    const button =
        <button className="btn btn-xs btn-danger float-right" onClick={e => showConfirmDialog(e)}>
            <i className="far fa-trash-alt" /> Delete
        </button>

    if (data || isLoading) {
        return (
            <>
                {button}
                <ConfirmDeletionDialog
                    isLoading={isLoading}
                    title={data ? `Confirm deletion of news category: ${data.title}` : ''}
                    description={''}
                    onCancel={() => closeDialog()}
                    onConfirm={() => confirmDelete()}
                />
            </>
        );
    }

    return button;
};

export default NewsCategoryDeleteButton;
