import WithMainLayoutPage from "../../WithMainLayoutPage";
import PageCard from "layouts/main/components/PageCard";
import {Link, useNavigate} from "react-router-dom";
import BannerZoneForm from "../BannerZoneForm";
import {ErrorHandling} from "helpers/error-handling";
import {useEffect, useState} from "react";
import {GlobalState} from "state/GlobalState";
import {BannerZoneApi} from "HtvApi/Api";

const BannerZoneCreatePage = () => {
    useEffect(() => {
        GlobalState.setPageTitle('Banner management');
        GlobalState.setLayoutPageTitle('Banner management');
    }, []);

    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const createBannerZone = data => {
        setLoading(true);

        BannerZoneApi.bannerZoneCreate({bannerZoneBannerZoneCreate: data})
            .then(() => {
                GlobalState.setSuccess('Successful operation!', 'Banner zone created.');
                navigate('/banners', {state: {keepMessages: true}});
            })
            .catch(error => {
                ErrorHandling.GlobalStateError(error);
                setLoading(false);
            })
    }

    return <PageCard
        title="Create new banner zone"
        loading={loading}
        actions={(
            <Link to="/banners" className="btn btn-sm btn-danger">
                <i className="fas fa-arrow-left" />&nbsp;&nbsp;&nbsp;Back to banner zone list
            </Link>
        )}
    >
        <BannerZoneForm onSubmit={createBannerZone} />
    </PageCard>
};

export default WithMainLayoutPage(BannerZoneCreatePage);
