import {useTable, useSortBy, useFilters} from "react-table";
import {useEffect, useRef} from "react";
import DataTableTextFilter from "./DataTableTextFilter";

const DataTableInner = ({columns, data, sorting, onSort, search, onSearch}) => {
    console.log('--- RENDERING: DataTableInner ---');

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        state: { sortBy, filters },
        setSortBy,
        setAllFilters,
    } = useTable(
        {
            columns,
            data,
            defaultColumn: {
                Filter: DataTableTextFilter
            },
            manualSortBy: true,
            manualFilters: true,
            initialState: {
                sortBy: [
                    sorting,
                ],
                filters: search,
            },
            disableMultiSort: true,
            disableSortRemove: true,
            autoResetSortBy: true,
        },
        useFilters,
        useSortBy,
    );

    // When default sorting is set, do not execute sorting change if the sortBy is different than the initial sorting.
    const defaultSortingSet = useRef(false);
    defaultSortingSet.current = false;

    // Reset sorting to the default if `sorting` is changed.
    // `sorting` is changed when sorting is changed from the previous component.
    // This should apply it here as default sorting and do not trigger sorting change.
    useEffect(() => {
        defaultSortingSet.current = true;
        setSortBy([sorting]);
        setAllFilters(search);
    }, [setSortBy, sorting, setAllFilters, search]);

    // If sorting is changed from the given one, then it's user-triggered and should be applied on top level.
    useEffect(() => {
        if (!defaultSortingSet.current && (sortBy[0].id !== sorting.id || sortBy[0].desc !== sorting.desc)) {
            console.log('Applying new sort...', sortBy[0], sorting);
            onSort(sortBy[0]);
        }
    }, [onSort, sortBy, sorting]);

    useEffect(() => {
        if (!defaultSortingSet.current && JSON.stringify(search) !== JSON.stringify(filters)) {
            let searchParams = {};
            for (let filter of filters) {
                searchParams[filter.id] = filter.value;
            }
            onSearch(searchParams);
            console.log('Apply new filters: ', filters, ' Old ', search);
        }
    }, [onSearch, filters, search]);

    return (
        <table {...getTableProps()} className="table table-bordered table-striped">
            <thead>
                {headerGroups.map(headerGroup => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map(column => (
                            <th {...column.getHeaderProps(column.getSortByToggleProps())} className="align-top">
                                {column.render('Header')}
                                {column.isSorted
                                    ? (
                                        <span>
                                            &nbsp;
                                            {column.isSortedDesc
                                                ? (<i className="fas fa-sort-down" />)
                                                : (<i className="fas fa-sort-up" />)
                                            }
                                        </span>
                                    )
                                    : ''
                                }
                                <div>
                                    {column.canFilter ? column.render('Filter') : null}
                                </div>
                            </th>
                        ))}
                    </tr>
                ))}
            </thead>
            <tbody {...getTableBodyProps()}>
                {rows.map(row => {
                    prepareRow(row);
                    return (
                        <tr {...row.getRowProps()}>
                            {row.cells.map(cell => (
                                <td {...cell.getCellProps()}>
                                    {cell.render('Cell')}
                                </td>
                            ))}
                        </tr>
                    )
                })}
            </tbody>
            <tfoot>

            </tfoot>
        </table>
    )
};

export default DataTableInner;
