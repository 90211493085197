import {observer} from "mobx-react";
import {useEffect} from "react";
import NonReactiveState from "../../state/NonReactiveState";
import {Alert} from "react-bootstrap";
import {GlobalState} from "../../state/GlobalState";

const Alerts = observer(() => {
    let errorAlert = '';
    let successAlert = '';

    useEffect(() => {
        if (GlobalState.hasError || GlobalState.hasSuccess) {
            if (NonReactiveState.messageDisplayed) {
                console.log('Message already displayed, removing...');
                GlobalState.clearMessages();
            } else {
                console.log('First time message, do nothing...');
                NonReactiveState.messageDisplayed = true;
            }
        }
    });

    if (GlobalState.hasError) {
        errorAlert = (
            <Alert variant="danger">
                <Alert.Heading>{GlobalState.errorTitle}</Alert.Heading>
                <p>{GlobalState.errorDescription}</p>
            </Alert>
        )
    }

    if (GlobalState.hasSuccess) {
        successAlert = (
            <Alert variant="success">
                <Alert.Heading>{GlobalState.successTitle}</Alert.Heading>
                <p>{GlobalState.successDescription}</p>
            </Alert>
        )
    }

    return (
        <>
            {errorAlert}
            {successAlert}
        </>
    )
});

export default Alerts;
