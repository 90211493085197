import {Button, Modal} from "react-bootstrap";
import Locales from "../../Locales";
import dayjs from "dayjs";
import HtvApi from "../../htv-api/HtvApi";
import {useState} from "react";
import Loader from "../../layouts/main/components/Loader";
import {ErrorHandling} from "../../helpers/error-handling";

const VideoInformationDialogButton = ({videoId}) => {
    const [displayModal, setDisplayModal] = useState(false);
    const [videoData, setVideoData] = useState({});

    const displayVideoData = () => {
        setDisplayModal(true);
        HtvApi.Videos.Get(videoId)
            .then(response => setVideoData(response))
            .catch((error) => {
                ErrorHandling.GlobalStateError(error);
                setDisplayModal(false);
            });
    }

    const informationButton = (
        <button className="btn btn-info btn-sm" onClick={() => displayVideoData()}>
            <i className="fas fa-info-circle" /> Info
        </button>
    );

    if (!Object.prototype.hasOwnProperty.call(videoData,'id')) {
        return (
            <>
                {informationButton}
                <Modal
                    show={displayModal}
                    onHide={() => setDisplayModal(false)}
                    size="xl"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <Loader />
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">Loading video information...</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => setDisplayModal(false)} className="mr-auto">
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>
            </>
        );
    }

    return (
        <>
            {informationButton}
            <Modal
                show={displayModal}
                onHide={() => setDisplayModal(false)}
                size="xl"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">Video information</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <dl className="row">
                        <dt className="col-sm-4">ID:</dt>
                        <dd className="col-sm-8">{videoData['id']}</dd>

                        <dt className="col-sm-4">Title:</dt>
                        <dd className="col-sm-8">{videoData['titleTranslations'].map(titleTranslation => {
                            return (
                                <span key={titleTranslation['locale']}>
                                    <strong>{Locales[titleTranslation['locale']] + ': '}</strong> {titleTranslation['value']}
                                    <br />
                                </span>
                            );
                        })}</dd>

                        <dt className="col-sm-4">Description:</dt>
                        <dd className="col-sm-8">{videoData['descriptionTranslations'].map(descriptionTranslation => {
                            return (
                                <span key={descriptionTranslation['locale']}>
                                    <strong>{Locales[descriptionTranslation['locale']] + ': '}</strong> {descriptionTranslation['value']}
                                    <br />
                                </span>
                            );
                        })}</dd>

                        <dt className="col-sm-4">Slug (Link):</dt>
                        <dd className="col-sm-8">{videoData['slug']}</dd>

                        <dt className="col-sm-4">Source:</dt>
                        <dd className="col-sm-8">{videoData['source']}</dd>

                        <dt className="col-sm-4">Source identifier:</dt>
                        <dd className="col-sm-8">{videoData['sourceIdentifier']}</dd>

                        <dt className="col-sm-4">Duration:</dt>
                        <dd className="col-sm-8">{videoData['duration']}</dd>

                        <dt className="col-sm-4">Created at:</dt>
                        <dd className="col-sm-8">
                            {dayjs(videoData['createdAt']).format('D MMM YYYY H:mm:ss')}
                        </dd>

                        <dt className="col-sm-4">Created by:</dt>
                        <dd className="col-sm-8">{videoData['createdBy']['firstName']} {videoData['createdBy']['lastName']}</dd>

                        <dt className="col-sm-4">Updated at:</dt>
                        <dd className="col-sm-8">
                            {videoData['updatedAt'] ? dayjs(videoData['updatedAt']).format('D MMM YYYY H:mm:ss') : '-'}
                        </dd>

                        <dt className="col-sm-4">Updated by:</dt>
                        <dd className="col-sm-8">
                            {
                                videoData['updatedBy']
                                    ? videoData['updatedBy']['firstName'] + ' ' + videoData['updatedBy']['lastName']
                                    : '-'
                            }
                        </dd>

                        <dt className="col-sm-4">Status:</dt>
                        <dd className="col-sm-8">{videoData['status']}</dd>

                        <dt className="col-sm-4">Watch count:</dt>
                        <dd className="col-sm-8">{videoData['watchCount']}</dd>

                        <dt className="col-sm-4">Categories:</dt>
                        <dd className="col-sm-8">
                            <ul>
                                {videoData['categories'].map(category => (<li>{category['title']}</li>))}
                            </ul>
                        </dd>

                        <dt className="col-sm-4">Image:</dt>
                        <dd className="col-sm-8">
                            <img
                                src={HtvApi.Videos.ThumbnailUrl(videoData['id'], 400, 224)}
                                alt="Video thumbnail"
                            />
                        </dd>
                    </dl>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setDisplayModal(false)} className="mr-auto">
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
};

export default VideoInformationDialogButton;
