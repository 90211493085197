import {Button, Modal} from "react-bootstrap";
import {useRef, useState} from "react";
import FormUploadFile from "layouts/main/components/Form/FormUploadFile";
import {FileApi} from "HtvApi/Api";
import {ErrorHandling} from "helpers/error-handling";
import AuthorizedImage from "layouts/main/components/AuthorizedImage";
import Loader from "layouts/main/components/Loader";

const UploadFileDialog = ({onCancel, onSubmit}) => {
    const fileId = useRef('');
    const fileName = useRef('');
    const [loading, setLoading] = useState(false);

    const onFileSelect = file => {
        setLoading(true);

        FileApi.fileUpload({body: file})
            .then(response => {
                fileId.current = response.data['@id'];
                fileName.current = file.name;
                setLoading(false);
            })
            .catch(error => {
                ErrorHandling.GlobalStateError(error);
                onCancel();
            });
    };

    const onSubmitFn = () => {
        if (fileId.current.length < 1) {
            ErrorHandling.GlobalStateError('Could not upload the file. Did you pick up a valid file?');
            onCancel();
            return;
        }

        onSubmit(fileId.current, fileName.current);
    };

    return <>
        <Modal
            show={true}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            onHide={onCancel}
        >
            <Modal.Header closeButton>
                <Modal.Title>Upload new file</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <FormUploadFile
                    label="Choose file"
                    placeholder={"Only jpeg/png images are currently supported"}
                    onChange={file => onFileSelect(file)}
                />

                {fileId.current.length > 0 ? (
                    <div className="text-center">
                        Preview:<br />
                        <AuthorizedImage fileId={fileId.current} maxWidth={700} />
                    </div>
                ) : ''}
                {loading ? <Loader /> : ''}
            </Modal.Body>

            <Modal.Footer>
                <Button variant="secondary" onClick={onCancel}>Close</Button>
                <Button variant="success" id="object-storage-upload-file-submit" onClick={onSubmitFn}>Create</Button>
            </Modal.Footer>
        </Modal>
    </>
};

export default UploadFileDialog;
