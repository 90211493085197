import {Tab, Tabs} from "react-bootstrap";
import FormTextField from "../../../../layouts/main/components/Form/FormTextField";
import FormChoice from "../../../../layouts/main/components/Form/FormChoice";
import {Link} from "react-router-dom";
import {useCallback, useRef} from "react";
import Locales from "../../../../Locales";

const VideoCategoriesEditForm = ({categoryData, onSubmit}) => {
    const data = useRef({
        titleTranslations: categoryData.titleTranslations,
        slug: categoryData.slug,
        status: categoryData.status,
    });

    const onSubmitEvent = event => {
        event.preventDefault();
        onSubmit(data.current);
    };

    const i18nTabs = [];

    for (const [locale, localeTitle] of Object.entries(Locales)) {
        i18nTabs.push(
            <Tab eventKey={locale} title={localeTitle} key={locale}>
                <div className="mt-3">
                    <FormTextField
                        label={'Title (' + localeTitle + ')'}
                        initialValue={data.current.titleTranslations[locale]}
                        // eslint-disable-next-line react-hooks/rules-of-hooks
                        onChange={useCallback(
                            value => data.current.titleTranslations[locale] = value, [locale]
                        )}
                    />
                </div>
            </Tab>
        )
    }

    return (
        <form onSubmit={onSubmitEvent}>
            <Tabs>
                {i18nTabs}
            </Tabs>

            <FormTextField
                label='Link'
                initialValue={data.current.slug}
                onChange={useCallback(value => data.current.slug = value, [])}
            />

            <FormChoice
                name="status"
                label="Status"
                options={{
                    enabled: {
                        label: 'Enabled',
                        color: 'green',
                        icon: 'fas fa-check-circle',
                    },
                    disabled: {
                        label: 'Disabled',
                        color: 'red',
                        icon: 'fas fa-times-circle',
                    },
                }}
                initialValue={data.current.status}
                onChange={useCallback(value => data.current.status = value, [])}
            />

            <div>
                <button
                    type="submit"
                    className="btn btn-success"
                >
                    Save video category
                </button>
                <Link
                    to="/videos/categories"
                    type="button"
                    className="btn btn-danger float-right"
                >
                    Cancel (changes will be lost)
                </Link>
            </div>
        </form>
    );
};

export default VideoCategoriesEditForm;
