import {Link} from "react-router-dom";
import './Navigation.css';
import LoggedUser from "../LoggedUser";
import NavigationItem from "./NavigationItem";

export default function Navigation() {
    return (
        <aside className="main-sidebar sidebar-dark-primary elevation-4">
            <Link className="brand-link" to="/">
                <span className="brand-text font-weight-light">HTV Admin</span>
            </Link>
            <div className="sidebar">
                <LoggedUser />

                <nav className="mt-2">
                    <ul className="nav nav-pills nav-sidebar flex-column"
                        role="menu"
                        data-accordion="false"
                    >
                        {/* Index - dashboard */}
                        <NavigationItem
                            text="Dashboard"
                            icon="fas fa-home"
                            target="/"
                            exactOnly={true}
                        />

                        {/* Videos */}
                        <NavigationItem
                            text="Videos"
                            icon="fas fa-video"
                            activePath="/videos"
                            subNavigation={[
                                {
                                    text: 'List',
                                    icon: 'fas fa-chevron-right',
                                    target: '/videos',
                                    exactOnly: true
                                },
                                {
                                    text: 'Thumbnail resolutions',
                                    icon: 'fas fa-chevron-right',
                                    target: '/videos/thumbnail-resolutions',
                                },
                                {
                                    text: 'Categories',
                                    icon: 'fas fa-chevron-right',
                                    target: '/videos/categories',
                                },
                                {
                                    text: 'Recommended',
                                    icon: 'fas fa-chevron-right',
                                    target: '/videos/recommended',
                                },
                            ]}
                        />

                        {/* News */}
                        <NavigationItem
                            text="News"
                            icon="fas fa-newspaper"
                            activePath="/news"
                            subNavigation={[
                                {
                                    text: 'News list',
                                    icon: 'fas fa-chevron-right',
                                    target: '/news',
                                    exactOnly: true
                                },
                                {
                                    text: 'Image sizes',
                                    icon: 'fas fa-chevron-right',
                                    target: '/news/image-sizes',
                                },
                                {
                                    text: 'Categories',
                                    icon: 'fas fa-chevron-right',
                                    target: '/news/categories',
                                },
                                {
                                    text: 'Recommended',
                                    icon: 'fas fa-chevron-right',
                                    target: '/news/recommended',
                                },
                            ]}
                        />

                        {/* Banners */}
                        <NavigationItem
                            text="Banners"
                            icon="fas fa-ad"
                            target="/banners"
                        />

                        {/* Object Storage */}
                        <NavigationItem
                            text="Object Storage"
                            icon="fas fa-folder-open"
                            target="/object-storage"
                        />

                        {/* Contact messages */}
                        <NavigationItem
                            text="Contact Messages"
                            icon="far fa-envelope-open"
                            target="/contact-messages"
                        />

                        {/* Subscriptions */}
                        <NavigationItem
                            text="Subscriptions"
                            icon="fas fa-rss"
                            target="/subscriptions"
                        />

                        {/* Settings */}
                        <NavigationItem
                            text="Settings"
                            icon="fas fa-cog"
                            target="/settings"
                        />
                    </ul>
                </nav>
            </div>
        </aside>
    );
}
