export default class Preferences
{
    constructor(htvApi) {
        this.htvApi = htvApi;
    }

    List(names) {
        let query = [];

        names.forEach(name => query.push('search[name][]=' + encodeURIComponent(name)))

        return this.htvApi.Call(
            this.htvApi.url + '/preferences?' + query.join('&'),
        )
    }

    Get(id) {
        return this.htvApi.Call(this.htvApi.url + '/preferences/' + id);
    }
}
