import {useState} from "react";
import uniqueId from "../../../../helpers/unique-id";
import Select from "react-select";

const FormSelect = ({label, value, values, onChange}) => {
    const [id] = useState(uniqueId());

    return (
        <div className="form-group row">
            <label
                htmlFor={id}
                className="col-sm-2 col-form-label"
            >
                {label}:
            </label>
            <div className="col-sm-10">
                <Select
                    id={id}
                    autoComplete="off"
                    value={value}
                    options={values}
                    onChange={value => onChange(value)}
                />
            </div>
        </div>
    )
};

export default FormSelect;
