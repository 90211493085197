import {useState} from "react";
import CreateDirectoryDialog from "pages/ObjectStorage/CreateDirectoryDialog";

const CreateDirectoryDialogButton = ({onDirectoryCreateRequest}) => {
    const [modalOpen, setModalOpen] = useState(false);

    const onCreateSubmit = value => {
        onDirectoryCreateRequest(value);
        setModalOpen(false);
    };

    return <>
        <button className="btn btn-sm btn-success" onClick={() => setModalOpen(true)}>
            <i className="fas fa-plus" />&nbsp;&nbsp;&nbsp;Add new directory
        </button>
        {modalOpen ?
            <CreateDirectoryDialog
                onCancel={() => setModalOpen(false)}
                onSubmit={onCreateSubmit}
            />
        : ''}
    </>;
}

export default CreateDirectoryDialogButton;
