import {Button, Grid, TextField} from "@mui/material";
import SaveIcon from '@mui/icons-material/Save';
import Loader from "layouts/main/components/Loader";
import {useState} from "react";
import {ErrorHandling} from "helpers/error-handling";
import {GlobalState} from "state/GlobalState";
import {PreferenceApi} from "HtvApi/Api";

const SettingControl = ({name, label, initialValue}) => {
    const [isLoading, setIsLoading] = useState(false);
    const [value, setValue] = useState(initialValue);

    const saveSetting = () => {
        setIsLoading(true);

        PreferenceApi
            .preferenceSet({name: name, preferenceData: {name: name, value: value}})
            .then(() => {
                GlobalState.setSuccess('Successful operation!', 'Setting saved!')
            })
            .catch(ErrorHandling.GlobalStateError)
            .then(() => setIsLoading(false));
    };

    return <Grid container direction="row" alignItems="center" columnSpacing={2}>
        <Grid item xs={10}>
            <TextField
                label={label}
                variant="outlined"
                value={value}
                onChange={event => setValue(event.target.value)}
                inputProps={{'data-name': name}}
                fullWidth
            />
        </Grid>
        <Grid item xs={2}>
            <Button
                variant="contained"
                size="large"
                onClick={saveSetting}
                data-name={name}
            >
                <SaveIcon /> Save
            </Button>
        </Grid>
        {isLoading ? <Loader small /> : null}
    </Grid>
}

export default SettingControl;
