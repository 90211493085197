class BrowserStorage {
    set(key, value, expirationInSeconds = null) {
        let data = {value};

        if (Number.isInteger(expirationInSeconds)) {
            data['validUntil'] = new Date().getTime() + (expirationInSeconds * 1000);
        }

        window.localStorage.setItem(key, JSON.stringify(data));
    }

    get(key, defaultValue = null) {
        let data = window.localStorage.getItem(key);

        if (data === null) {
            return defaultValue;
        }

        data = JSON.parse(data);

        if (Object.prototype.hasOwnProperty.call(data, 'validUntil')) {
            let validUntil = data['validUntil'];

            if (validUntil >= new Date().getTime()) {
                return data['value'];
            }

            window.localStorage.removeItem(key);

            return defaultValue;
        }

        return data['value'];
    }
}

const browserStorage = new BrowserStorage();

export {browserStorage as BrowserStorage};
