import DateTimePicker from "react-datetime-picker";
import 'react-datetime-picker/dist/DateTimePicker.css';
import 'react-calendar/dist/Calendar.css';
import 'react-clock/dist/Clock.css';
import './FormDateTimePicker.css';
import {useCallback, useState} from "react";

const FormDateTimePicker = ({label, initialValue, onChange, required = false}) => {
    if (typeof initialValue === 'string') {
        initialValue = new Date(initialValue);
    }

    const [value, setValue] = useState(initialValue);

    const onChangeFn = useCallback(value => {
        setValue(value);
        onChange(value);
    }, [onChange]);

    return (
        <div className="form-group row">
            <label className="col-sm-2 col-form-label">{label}:</label>
            <div className="col-sm-10">
                <DateTimePicker
                    format="d MMMM y H:mm:ss"
                    value={value}
                    onChange={onChangeFn}
                    required={required}
                    clearIcon={null}
                    calendarIcon={null}
                    data-testid="form-datetime-picker"
                />
            </div>
        </div>
    )
};

export default FormDateTimePicker;
