import {useEffect, useRef, useState} from "react";
import {GlobalState} from "../../state/GlobalState";
import PageCard from "../../layouts/main/components/PageCard";
import SettingControl from "./SettingControl";
import WithMainLayoutPage from "../WithMainLayoutPage";
import {Grid} from "@mui/material";
import {HtvApi} from "../../htv-api/HtvApi";
import {ErrorHandling} from "../../helpers/error-handling";

const SettingsPage = () => {
    useEffect(() => {
        GlobalState.setPageTitle('Settings');
        GlobalState.setLayoutPageTitle('Settings');
    }, []);
    const [isLoading, setIsLoading] = useState(true);

    const settings = useRef([
        {
            name: 'stream-url',
            label: 'Live stream URL',
        },
        {
            name: 'website-title-suffix',
            label: 'Website title',
        },
        {
            name: 'website-meta-keywords',
            label: 'Website SEO keywords',
        },
        {
            name: 'website-meta-description',
            label: 'Website SEO description',
        },
    ]);

    useEffect(() => {
        HtvApi.Preferences.List(settings.current.map(setting => setting.name))
            .then(response => {
                response['hydra:member'].forEach(responsePreference => {
                    settings.current.forEach(setting => {
                        if (responsePreference.name === setting.name) {
                            setting.value = responsePreference.value;
                        }
                    })
                })
            })
            .catch(ErrorHandling.GlobalStateError)
            .then(() => setIsLoading(false));
    }, [])

    return <PageCard
        title={'Change project and website settings'}
        loading={isLoading}
    >
        {isLoading ? '' :
            <Grid container rowSpacing={3}>
                {settings.current.map(setting =>
                    <Grid item xs={12} key={setting.name}>
                        <SettingControl
                            name={setting.name}
                            label={setting.label}
                            initialValue={setting.value}
                        />
                    </Grid>
                )}
            </Grid>
        }
    </PageCard>
};

export default WithMainLayoutPage(SettingsPage);
