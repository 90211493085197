import FormTextField from "../../layouts/main/components/Form/FormTextField";
import * as yup from 'yup';
import Form from "../../layouts/main/components/Form/Form";
import FormSubmit from "../../layouts/main/components/Form/FormSubmit";
import FormCancel from "../../layouts/main/components/Form/FormCancel";

const BannerZoneForm = ({data = null, onSubmit}) => {
    const defaults = {
        id: null,
        key: '',
        description: '',
        width: '',
        height: '',
    };
    const formData = Object.assign({}, defaults, data);

    const validation = yup.object().shape({
        key: yup.string().min(2).max(100).matches(/^[a-z][0-9a-z-]+$/).trim().lowercase().required(),
        description: yup.string().min(1).max(255).trim().required(),
        width: yup.number().min(10).max(3840).integer().required(),
        height: yup.number().min(10).max(2160).integer().required(),
    });

    return (
        <Form
            id="banner-zone-form"
            initialData={formData}
            validation={validation}
            onSubmit={onSubmit}
        >
            {({onChange, errors}) => (
                <>
                    <FormTextField
                        name="key"
                        label={'Key'}
                        placeholder={'Key to reference and load the banner zone on the client side.'}
                        initialValue={formData['key']}
                        errors={errors.key || null}
                        onChange={onChange}
                    />

                    <FormTextField
                        name="description"
                        label={'Description'}
                        placeholder={'Describe the banner zone with free text.'}
                        initialValue={formData['description']}
                        errors={errors.description || null}
                        onChange={onChange}
                    />

                    <FormTextField
                        name="width"
                        label={'Width'}
                        placeholder={'Width in pixels'}
                        disabled={formData.id !== null}
                        initialValue={formData['width']}
                        errors={errors.width || null}
                        onChange={onChange}
                    />

                    <FormTextField
                        name="height"
                        label={'Height'}
                        placeholder={'Height in pixels'}
                        disabled={formData.id !== null}
                        initialValue={formData['height']}
                        errors={errors.height || null}
                        onChange={onChange}
                    />

                    <FormSubmit label="Save banner zone" />
                    <FormCancel label="Cancel (changes will be lost)" redirectTo="/banners" />
                </>
            )}
        </Form>
    )
};

export default BannerZoneForm;