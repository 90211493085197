const ResolvedVideoTile = ({videoData, cancelResolve}) => {
    return (
        <div className="row">
            <div className="col-12">
                <div className="card card-success">
                    <div className="card-header">
                        <h3 className="card-title">Resolved video</h3>
                    </div>
                    <div className="card-body">
                        <div className="row">
                            <img
                                className="col-3"
                                style={{alignSelf: 'start'}}
                                src={videoData.images[videoData.images.length-1].uri}
                                alt="Video thumbnail"
                            />
                            <div className="col-9">
                                <h4>{videoData.title}</h4>
                                <p>{videoData.description}</p>
                            </div>
                        </div>
                    </div>
                    <div className="card-footer">
                        <button
                            className="btn btn-danger float-right"
                            onClick={cancelResolve}
                        >
                            Change resolved video (changes will be lost)
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ResolvedVideoTile;
