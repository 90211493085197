export default class Users
{
    constructor(htvApi) {
        this.htvApi = htvApi;
    }

    Profile() {
        return this
            .htvApi
            .Call(this.htvApi.url + '/user/profile');
    }
}
