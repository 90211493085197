import {Button, Modal} from "react-bootstrap";
import Locales from "../../Locales";
import {useState} from "react";
import HtvApi from "../../htv-api/HtvApi";
import {ErrorHandling} from "../../helpers/error-handling";
import Loader from "../../layouts/main/components/Loader";
import {GlobalState} from "../../state/GlobalState";

const VideoDeleteDialogButton = ({videoId, onVideoDeleted}) => {
    const [modalState, setModelState] = useState({
        display: false,
        isLoading: true,
        videoData: undefined,
    });

    const hideModal = () => {
        setModelState({display: false, isLoading: true, videoData: undefined});
    };

    const confirmVideoDeletion = () => {
        setModelState({
            display: true,
            isLoading: true,
            videoData: undefined,
        });
        HtvApi.Videos.Get(videoId)
            .then(response => {
                setModelState({
                    display: true,
                    isLoading: false,
                    videoData: response,
                });
            })
            .catch((error) => {
                ErrorHandling.GlobalStateError(error);
                hideModal();
            });
    }

    const deleteVideo = () => {
        HtvApi.Videos.Delete(videoId)
            .then(() => GlobalState.setSuccess('Successful operation!', 'Video deleted!'))
            .catch(ErrorHandling.GlobalStateError)
            .then(() => {
                hideModal();
                onVideoDeleted();
            });
    };

    const deleteButton = (
        <button className="btn btn-danger btn-sm" onClick={() => confirmVideoDeletion()}>
            <i className="far fa-trash-alt" /> Delete
        </button>
    );

    if (modalState.isLoading) {
        return (
            <>
                {deleteButton}
                <Modal
                    show={modalState.display}
                    onHide={() => hideModal()}
                    size="xl"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <Loader />
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">Loading...</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => hideModal()} className="mr-auto">
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>
            </>
        );
    }

    return (
        <>
            {deleteButton}
            <Modal
                show={modalState.display}
                onHide={() => hideModal()}
                size="xl"
                aria-labelledby="contained-modal-title-vcenter-delete"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter-delete">Confirm video deletion</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Confirm deletion of video with ID: {modalState.videoData['id']}
                    <br />
                    {modalState.videoData['titleTranslations'].map(titleTranslation => {
                        return (
                            <div key={titleTranslation['locale']}>
                                <strong>{Locales[titleTranslation['locale']] + ': '}</strong> {titleTranslation['value']}
                            </div>
                        );
                    })}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => hideModal()} className="mr-auto">
                        Close
                    </Button>
                    <Button variant="danger" onClick={() => deleteVideo()}>
                        DELETE VIDEO (this is permanent!)
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
};

export default VideoDeleteDialogButton;
