import {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import BannerForm from "./BannerForm";
import {GlobalState} from "state/GlobalState";
import {ErrorHandling} from "helpers/error-handling";
import PageCard from "layouts/main/components/PageCard";
import BackButton from "layouts/main/components/Button/BackButton";
import WithMainLayoutPage from "../../../WithMainLayoutPage";
import {BannerApi, BannerZoneApi} from "HtvApi/Api";

const BannerCreatePage = () => {
    useEffect(() => {
        GlobalState.setPageTitle('Banner management');
        GlobalState.setLayoutPageTitle('Banner management');
    }, []);

    const {id} = useParams();

    const [state, setState] = useState({bannerZone: null, bannerZoneKey: null, loading: true});
    const navigate = useNavigate();

    useEffect(() => {
        BannerZoneApi.bannerZoneGet({id})
            .then(response => {
                setState(state => ({
                    ...state,
                    bannerZone: response.data['@id'],
                    bannerZoneKey: response.data.key,
                    loading: false,
                }));
            })
            .catch(error => {
                ErrorHandling.GlobalStateError(error);
                navigate('/banners', {state: {keepMessages: true}});
            });
    }, [id, navigate]);

    const createBanner = data => {
        setState(state => ({...state, loading: true}));

        BannerApi.bannerCreate({bannerCreate: data})
            .then(() => {
                GlobalState.setSuccess('Successful operation!', 'Banner created.');
                navigate(`/banners/zone/${id}`, {state: {keepMessages: true}});
            })
            .catch(error => {
                ErrorHandling.GlobalStateError(error);
                setState(state => ({...state, loading: false}));
            })
    }

    return <PageCard
        title={state.loading ? '' : `Create new banner in zone: ${state.bannerZoneKey}`}
        loading={state.loading}
        actions={(
            <BackButton to={`/banners/zone/${id}`} text="Back to banners list" />
        )}
    >
        <BannerForm data={{bannerZone: state.bannerZone ?? ''}} onSubmit={createBanner} />
    </PageCard>
};

export default WithMainLayoutPage(BannerCreatePage);
