import {useEffect, useState} from "react";
import {GlobalState} from "state/GlobalState";
import HtvApi from "../../../htv-api/HtvApi";
import {ErrorHandling} from "helpers/error-handling";
import PageCard from "../../../layouts/main/components/PageCard";
import WithMainLayoutPage from "../../WithMainLayoutPage";

const NewsImageSizesPage = () => {
    useEffect(() => {
        GlobalState.setPageTitle('News image sizes');
        GlobalState.setLayoutPageTitle('News management');
    }, []);

    const [resolutionsList, setResolutionsList] = useState([]);
    const [dataVersion, setDataVersion] = useState(1);
    const [newImageSize, setNewImageSize] = useState({label: '', type: 'fixed', width: '', height: ''});

    useEffect(() => {
        HtvApi.NewsImageSizes.List().then(response => {
            setResolutionsList(response['hydra:member']);
        }).catch(ErrorHandling.GlobalStateError)
    }, [dataVersion]);

    let resolutionRows = [];

    for (const resolution of resolutionsList) {
        resolutionRows.push(
            <tr key={resolution['id']}>
                <td>{resolution['label']}</td>
                <td>{resolution['type']}</td>
                <td>{resolution['width']}</td>
                <td>{resolution['height']}</td>
                <td>
                    {/*<NewsImageSizeDeleteDialogButton*/}
                    {/*    id={resolution['id']}*/}
                    {/*    onConfirm={() => setDataVersion(version => version + 1)}*/}
                    {/*/>*/}
                </td>
            </tr>
        )
    }

    const onSubmit = (e) => {
        e.preventDefault();
        HtvApi.NewsImageSizes.Create(newImageSize).then(() => {
            GlobalState.setSuccess('News image size added!');
            setNewImageSize({label: '', type: 'fixed', width: '', height: ''});
            setDataVersion(version => version + 1);
        }).catch(ErrorHandling.GlobalStateError);
    };

    return (
        <PageCard title='News image sizes'>
            <form onSubmit={onSubmit}>
                <table className="table table-hover text-nowrap">
                    <thead>
                        <tr>
                            <th>Label</th>
                            <th>Type</th>
                            <th>Width</th>
                            <th>Height</th>
                            <th>Options</th>
                        </tr>
                    </thead>
                    <tbody>

                        <tr key="add-new">
                            <td>
                                <input
                                    className="form-control form-control-sm"
                                    name={'label'}
                                    value={newImageSize.label}
                                    onChange={e => setNewImageSize(data => ({...data, label: e.target.value}))}
                                />
                            </td>
                            <td>
                                <select
                                    className="form-control form-control-sm"
                                    name={'type'}
                                    value={newImageSize.type}
                                    onChange={e => setNewImageSize(data => ({...data, type: e.target.value}))}
                                >
                                    <option value={'fixed'}>Fixed</option>
                                    <option value={'fixed-width'}>Fixed Width</option>
                                    <option value={'fixed-height'}>Fixed Height</option>
                                </select>
                            </td>
                            <td>
                                <input
                                    className="form-control form-control-sm"
                                    name={'width'}
                                    value={newImageSize.width}
                                    onChange={e => setNewImageSize(data => ({...data, width: e.target.value}))}
                                />
                            </td>
                            <td>
                                <input
                                    className="form-control form-control-sm"
                                    name={'height'}
                                    value={newImageSize.height}
                                    onChange={e => setNewImageSize(data => ({...data, height: e.target.value}))}
                                />
                            </td>
                            <td>
                                <button className="btn btn-sm btn-success" type="submit">
                                    <i className="fas fa-plus" /> Add
                                </button>
                            </td>
                        </tr>

                        {resolutionRows}
                    </tbody>
                </table>
            </form>
        </PageCard>
    );
};

export default WithMainLayoutPage(NewsImageSizesPage);
