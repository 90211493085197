import { useParams } from "react-router-dom";
import WithMainLayoutPage from "../../WithMainLayoutPage";
import {useEffect, useState} from "react";
import {GlobalState} from "../../../state/GlobalState";
import HtvApi from "../../../htv-api/HtvApi";
import ResolvedVideoTile from "./ResolvedVideoTile";
import EditVideoForm from "./EditVideoForm";

const VideoEditPage = () => {
    console.log('--- RENDERING: Video edit page ---');
    let {id} = useParams();

    const [videoData, setVideoData] = useState({
        loaded: false,
        title: '',
        description: '',
    });

    useEffect(() => {
        GlobalState.setPageTitle('Editing video');
        GlobalState.setLayoutPageTitle('Editing video');
    }, []);

    useEffect(() => {
        HtvApi.Videos.Get(id).then((video) => {
            setVideoData({
                loaded: true,
                title: video.title,
                description: video.description,
                slug: video.slug,
                status: video.status,
                categories: video.categories.map(category => category.id),
            });
        });
    }, [id]);

    return (
        <>
            <ResolvedVideoTile id={id} videoData={videoData} />
            {videoData.loaded ? (<EditVideoForm id={id} videoData={videoData} />) : ''}
        </>
    );
};

export default WithMainLayoutPage(VideoEditPage);
