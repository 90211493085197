import {Link} from "react-router-dom";

const FormCancel = ({label, redirectTo}) => {
    return (
        <Link
            to={redirectTo}
            type="button"
            className="btn btn-danger float-right"
        >
            {label}
        </Link>
    )
}

export default FormCancel;