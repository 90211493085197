import {Link} from "react-router-dom";
import WithMainLayoutPage from "../../WithMainLayoutPage";
import Nestable from "react-nestable";
import {useEffect, useState} from "react";
import HtvApi from "../../../htv-api/HtvApi";
import 'react-nestable/dist/styles/index.css';
import './VideoCategoriesPage.css';
import {GlobalState} from "../../../state/GlobalState";
import {ErrorHandling} from "../../../helpers/error-handling";
import PageCard from "../../../layouts/main/components/PageCard";
import VideoCategoriesDeleteButton from "./VideoCategoriesDeleteButton";

const nestableOutputMap = (items) => {
    let result = [];

    items.forEach(item => {
        let resultItem = {id: item.id};

        if (Object.prototype.hasOwnProperty.call(item, 'children')) {
            let children = nestableOutputMap(item.children);

            if (children.length) {
                resultItem['children'] = children;
            }
        }

        result.push(resultItem);
    });

    return result;
};

const parseCategoriesIntoNestedList = (categories, parentId = null) => {
    let nestedCategories = [];

    categories.forEach(category => {
        const categoryParent = category?.parent?.id || null;

        if (categoryParent !== parentId) {
            return;
        }

        const children = parseCategoriesIntoNestedList(categories, category.id);

        let item = {
            id: category.id,
            text: category.title,
            enabled: category.status === 'enabled',
            slug: category.slug
        };

        if (children.length) {
            item['children'] = children;
        }

        nestedCategories.push(item);
    });

    return nestedCategories;
};

const VideoCategoriesPage = () => {
    const [categories, setCategories] = useState([]);
    const [dataVersion, setDataVersion] = useState(1);

    useEffect(() => {
        HtvApi.VideoCategories.List().then(response => {
            setCategories(parseCategoriesIntoNestedList(response['hydra:member']));
        }).catch(ErrorHandling.GlobalStateError);
    }, [dataVersion]);

    const saveCategoriesOrder = () => {
        const requestData = nestableOutputMap(categories);

        HtvApi.VideoCategories.Reorder(requestData).then(() => {
            GlobalState.setSuccess('Successful operation!', 'Videos positions saved!');
        }).catch(ErrorHandling.GlobalStateError);
    };

    const renderItem = ({ item }) => {
        return (
            <div className={'video-category-item ' + (item.enabled ? 'category-enabled' : 'category-disabled')}>
                <span>{item.text}</span>
                <span className={'video-category-slug ml-3 text-sm text-gray'}>{item.slug}</span>
                <VideoCategoriesDeleteButton
                    id={item.id}
                    onConfirm={() => setDataVersion(version => version + 1)}
                />
                <Link to={'/videos/categories/edit/' + item.id} className="btn btn-xs btn-warning float-right mr-2">
                    <i className="fas fa-pencil-alt" /> Edit
                </Link>
            </div>
        );
    }

    const actions = (
        <>
            <Link to="/videos/categories/add" className="btn btn-sm btn-success">
                <i className="fas fa-plus" />&nbsp;&nbsp;&nbsp;Add new
            </Link>
            <button className="btn btn-sm btn-primary ml-2" onClick={() => saveCategoriesOrder()}>
                <i className="fas fa-save" />&nbsp;&nbsp;&nbsp;Save positions
            </button>
        </>
    );

    return (
        <PageCard title='Video categories' actions={actions}>
            <Nestable
                items={categories}
                renderItem={renderItem}
                onChange={(data) => { setCategories(data.items) }}
            />
        </PageCard>
    );
};

export default WithMainLayoutPage(VideoCategoriesPage);
