import {createTheme, ThemeProvider} from "@mui/material";

const theme = createTheme({
    components: {
        MuiGrid: {
            styleOverrides: {
                root: {
                    position: 'relative',
                },
            },
        },
    },
});

const MUITheme = ({children}) => {
    return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
}

export default MUITheme;
