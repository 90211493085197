import Form from "../../../../layouts/main/components/Form/Form";
import FormTextField from "../../../../layouts/main/components/Form/FormTextField";
import FormSubmit from "../../../../layouts/main/components/Form/FormSubmit";
import FormCancel from "../../../../layouts/main/components/Form/FormCancel";
import * as yup from "yup";
import FormChoice from "../../../../layouts/main/components/Form/FormChoice";
import FormUploadFile from "../../../../layouts/main/components/Form/FormUploadFile";
import {ErrorHandling} from "../../../../helpers/error-handling";
import BannerFormImagePreview from "./BannerFormImagePreview";
import {useState} from "react";
import Loader from "../../../../layouts/main/components/Loader";
import {uriToId} from "../../../../helpers/functions";
import {BannerZoneApi, FileApi} from "../../../../HtvApi/Api";

const BannerForm = ({data, onSubmit}) => {
    const defaults = {
        id: null,
        bannerZone: '',
        imageFile: undefined,
        imageUrl: undefined,
        url: '',
        target: '_blank',
        status: 'enabled',
    };
    const formData = Object.assign({}, defaults, data);
    const [state, setState] = useState({bannerPreviewFileIRI: null, loading: false});

    if (!data.bannerZone) {
        return <></>
    }

    const validation = yup.object().shape({
        bannerZone: yup.string().min(36).max(60).required(),
        imageFile: yup.string().min(36).max(60).when(
                ['imageUrl'],
                {
                    is: value => typeof value !== 'undefined',
                    then: schema => schema.optional(),
                    otherwise: schema => schema.required()
                }
            ),
        url: yup.string().min(2).max(250).trim().required(),
        target: yup.string().oneOf(['_blank', '_self']).required(),
        status: yup.string().oneOf(['enabled', 'disabled']).required(),
    });

    const onImageChange = (file, onChange) => {
        setState(state => ({...state, loading: true}));
        FileApi.fileUpload({body: file})
            .then(response => {
                BannerZoneApi
                    .bannerZoneCropImage({
                        id: uriToId(formData.bannerZone),
                        bannerZoneBannerZoneCropImage: {imageFile: response.data['@id']}
                    })
                    .then(response => {
                        onChange(response.data['@id'], 'imageFile');
                        setState(state => ({...state, bannerPreviewFileIRI: response.data['@id'], loading: false}));
                    })
                    .catch(error => {
                        ErrorHandling.GlobalStateError(error);
                        setState(state => ({...state, loading: false}));
                    });
            })
            .catch(error => {
                ErrorHandling.GlobalStateError(error);
                setState(state => ({...state, loading: false}));
            });
    }

    let imagePreview = null;

    if (state.bannerPreviewFileIRI) {
        imagePreview = <BannerFormImagePreview fileIRI={state.bannerPreviewFileIRI} maxWidth={500} maxHeight={500} />
    } else if (data.imageUrl) {
        imagePreview = <BannerFormImagePreview imageUrl={data.imageUrl} maxWidth={500} maxHeight={500} />
    }

    return <Form
        id="banner-form"
        initialData={formData}
        validation={validation}
        onSubmit={onSubmit}
    >
        {({onChange, errors}) => (
            <>
                <FormUploadFile
                    label={`Image`}
                    placeholder={formData.imageFile || formData.imageUrl ? 'Change image' : 'Select image'}
                    onChange={file => onImageChange(file, onChange)}
                    errors={errors.imageFile || null}
                />

                {imagePreview}

                <FormTextField
                    name="url"
                    label={'URL'}
                    placeholder={'URL to which the client will be redirected when clicking on the banner'}
                    initialValue={formData.url}
                    errors={errors.url || null}
                    onChange={onChange}
                />

                <FormChoice
                    name="target"
                    label="Target"
                    initialValue={formData.target}
                    onChange={onChange}
                    options={{
                        _blank: {
                            label: 'New page',
                            color: 'green',
                            icon: 'fas fa-external-link-alt',
                        },
                        _self: {
                            label: 'Same page',
                            color: 'blue',
                            icon: 'far fa-file-alt',
                        },
                    }}
                />

                <FormChoice
                    name="status"
                    label="Status"
                    initialValue={formData.status}
                    onChange={onChange}
                    options={{
                        enabled: {
                            label: 'Enabled',
                            color: 'green',
                            icon: 'fas fa-check-circle',
                        },
                        disabled: {
                            label: 'Disabled',
                            color: 'red',
                            icon: 'fas fa-times-circle',
                        },
                    }}
                />

                <FormSubmit label="Save banner zone" />
                <FormCancel
                    label="Cancel (changes will be lost)"
                    redirectTo={`/banners/zone/${uriToId(formData['bannerZone'])}`}
                />

                {state.loading ? <Loader /> : ''}
            </>
        )}
    </Form>
};

export default BannerForm;
