import AuthorizedImage from "../../../../layouts/main/components/AuthorizedImage";

const BannerFormImagePreview = ({
    fileIRI = null,
    imageUrl = null,
    maxWidth = 1024,
    maxHeight = 768
}) => {
    let image = <img alt="preview" src={imageUrl} style={{maxWidth: maxWidth + 'px', maxHeight: maxHeight + 'px'}} />;

    if (fileIRI !== null) {
        image = <AuthorizedImage fileId={fileIRI} maxWidth={maxWidth} maxHeight={maxHeight} />
    }

    return <div className="form-group row">
        <div className="col-sm-2" />
        <div className="col-sm-10">
            {image}
        </div>
    </div>
}

export default BannerFormImagePreview;
