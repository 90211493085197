import {Button, Modal} from "react-bootstrap";
import Loader from "./Loader";

const LoadingDialog = ({onCancel}) => {
    return (
        <Modal
            show={true}
            onHide={() => onCancel()}
            size="xl"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Loader />
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">Loading...</Modal.Title>
            </Modal.Header>
            <Modal.Body>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => onCancel()} className="mr-auto">
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    )
};

export default LoadingDialog;