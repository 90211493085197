import Locales from "../../../Locales";
import {Tab, Tabs} from "react-bootstrap";
import FormTextField from "../../../layouts/main/components/Form/FormTextField";
import FormEditor from "../../../layouts/main/components/Form/FormEditor";
import FormChoice from "../../../layouts/main/components/Form/FormChoice";
import {Link, useNavigate} from "react-router-dom";
import Loader from "../../../layouts/main/components/Loader";
import HtvApi from "../../../htv-api/HtvApi";
import {GlobalState} from "../../../state/GlobalState";
import {useCallback, useRef, useState} from "react";
import VideoCategoriesSelect from "../VideoCategoriesSelect";
import {ErrorHandling} from "../../../helpers/error-handling";

const EditVideoForm = ({id, videoData}) => {
    let defaultTitles = {};
    let defaultDescriptions = {};

    for (const locale of Object.keys(Locales)) {
        defaultTitles[locale] = videoData.title;
        defaultDescriptions[locale] = videoData.description;
    }

    const data = useRef({
        titleTranslations: defaultTitles,
        descriptionTranslations: defaultDescriptions,
        slug: videoData.slug,
        status: videoData.status,
    });
    const navigate = useNavigate();

    const [categories, setCategories] = useState(videoData.categories);
    const [isLoading, setIsLoading] = useState(false);

    const onSubmit = (event) => {
        event.preventDefault();
        setIsLoading(true);

        HtvApi.Videos.Edit(id,{
            slug: data.current.slug,
            source: videoData.source,
            sourceIdentifier: videoData.identifier,
            status: data.current.status,
            categories: categories,
            titleTranslations: data.current.titleTranslations,
            descriptionTranslations: data.current.descriptionTranslations,
        }).then(response => {
            GlobalState.setSuccess('Successful operation!', 'Video ' + response.title + ' changes saved successfully!');
            navigate('/videos', {state: {keepMessages: true}});
        }).catch(error => {
            setIsLoading(false);
            ErrorHandling.GlobalStateError(error);
        });
    };

    const loader = isLoading ? (<Loader />) : '';
    const tabs = [];

    for (const [locale, localeTitle] of Object.entries(Locales)) {
        tabs.push(
            <Tab eventKey={locale} title={localeTitle} key={locale}>
                <div className="mt-3">
                    <FormTextField
                        label={'Title (' + localeTitle + ')'}
                        initialValue={data.current.titleTranslations[locale]}
                        // eslint-disable-next-line react-hooks/rules-of-hooks
                        onChange={useCallback(
                            value => data.current.titleTranslations[locale] = value, [locale]
                        )}
                    />
                </div>

                <FormEditor
                    label={`Description (${localeTitle})`}
                    initialValue={data.current.descriptionTranslations[locale]}
                    // eslint-disable-next-line react-hooks/rules-of-hooks
                    onChange={useCallback(
                        content => data.current.descriptionTranslations[locale] = content, [locale]
                    )}
                />
            </Tab>
        )
    }

    return (
        <div className="row">
            <div className="col-12">
                <div className="card card-info">
                    <div className="card-header">
                        <h3 className="card-title">Edit video details</h3>
                    </div>
                    <form
                        className="form-horizontal"
                        onSubmit={onSubmit}
                    >
                        <div className="card-body">
                            <Tabs>
                                {tabs}
                            </Tabs>
                            <hr />

                            <FormTextField
                                label={'Link'}
                                initialValue={data.current.slug}
                                onChange={useCallback(
                                    value => data.current.slug = value, []
                                )}
                            />

                            <FormChoice
                                name="status"
                                label="Status"
                                options={{
                                    enabled: {
                                        label: 'Enabled',
                                        color: 'green',
                                        icon: 'fas fa-check-circle',
                                    },
                                    disabled: {
                                        label: 'Disabled',
                                        color: 'red',
                                        icon: 'fas fa-times-circle',
                                    },
                                }}
                                initialValue={data.current.status}
                                onChange={useCallback(value => data.current.status = value, [])}
                            />

                            <VideoCategoriesSelect
                                label={'Categories'}
                                selected={categories}
                                onChange={values => setCategories(values)}
                            />
                        </div>
                        <div className="card-footer">
                            <button
                                type="submit"
                                className="btn btn-success"
                            >
                                Save changes
                            </button>
                            <Link
                                to="/videos"
                                type="button"
                                className="btn btn-danger float-right"
                            >
                                Cancel (changes will be lost)
                            </Link>
                        </div>
                    </form>
                    {loader}
                </div>
            </div>
        </div>
    );
};

export default EditVideoForm;
