import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import HtvApi from "../../../../htv-api/HtvApi";
import {GlobalState} from "../../../../state/GlobalState";
import {ErrorHandling} from "../../../../helpers/error-handling";
import PageCard from "../../../../layouts/main/components/PageCard";
import WithMainLayoutPage from "../../../WithMainLayoutPage";
import NewsCategoryForm from "../NewsCategoryForm";

const NewsCategoriesCreatePage = () => {
    useEffect(() => {
        GlobalState.setPageTitle('News management');
        GlobalState.setLayoutPageTitle('News management');
    }, []);

    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();

    const onSubmit = data => {
        setIsLoading(true);

        HtvApi.NewsCategories.Create({
            titleTranslations: data.titleTranslations,
            slug: data.slug,
            status: data.status,
        }).then((response) => {
            GlobalState.setSuccess('Successful operation!', 'News category ' + response.title + ' created successfully!');
            navigate('/news/categories', {state: {keepMessages: true}});
        }).catch(error => {
            setIsLoading(false);
            ErrorHandling.GlobalStateError(error);
        });
    }

    return (
        <PageCard title='Add news category' loading={isLoading}>
            {isLoading ? '' : <NewsCategoryForm onSubmit={onSubmit} />}
        </PageCard>
    );
};

export default WithMainLayoutPage(NewsCategoriesCreatePage);