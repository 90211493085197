import AuthorizedImage from "../../layouts/main/components/AuthorizedImage";
import PropTypes from "prop-types";
import {NewsImageModel} from "../../models/NewsImage";

const NewsImage = ({image}) => {
    if (image.url !== undefined) {
        return <img src={image.url} alt={image.label} />
    }

    if (image.fileID !== undefined) {
        return <AuthorizedImage fileId={image.fileID} testId={`preview-news-image-${image.label}`} />
    }

    return null;
};

NewsImage.propTypes = {
    image: PropTypes.instanceOf(NewsImageModel),
};

export default NewsImage;
