import {useSortable} from "@dnd-kit/sortable";
import {CSS} from "@dnd-kit/utilities";
import dayjs from "dayjs";
import {Link} from "react-router-dom";
import BannerDeleteDialogButton from "./zone/banners/BannerDeleteDialogButton";

const BannerSortableItem = ({banner, onDelete}) => {
    const {
        attributes,
        listeners,
        setNodeRef,
        transform,
        transition,
    } = useSortable({id: banner.id});

    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
    };

    return (
        <tr ref={setNodeRef} style={style}>
            <td>
                <img
                    src={banner.imageUrl}
                    style={{maxWidth: '400px', maxHeight: '100px'}}
                    alt="Banner preview"
                />
            </td>
            <td>
                <strong>
                    {banner.target === '_blank' ? 'New tab: ' : 'Same tab: '}
                </strong>
                {banner.url}
            </td>
            <td>
                {banner.status}
            </td>
            <td>
                {dayjs(banner.updatedAt).format('D MMM YYYY H:mm:ss')}
            </td>
            <td>
                <button type="button" className="btn btn-sm btn-info" {...attributes} {...listeners}>
                    <i className="fas fa-arrows-alt-v" />&nbsp;Move
                </button>
                &nbsp;
                <Link to={`/banners/edit/${banner.id}`} className="btn btn-sm btn-warning">
                    <i className="fas fa-pencil-alt" />&nbsp;Edit
                </Link>
                &nbsp;
                <BannerDeleteDialogButton id={banner.id} onConfirm={onDelete} />
            </td>
        </tr>
    );
}

export default BannerSortableItem;
