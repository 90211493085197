import PageCard from "layouts/main/components/PageCard";
import {useEffect, useState} from "react";
import {ObjectStorageApi} from "HtvApi/Api";
import {ErrorHandling} from "helpers/error-handling";
import CreateDirectoryDialogButton from "pages/ObjectStorage/CreateDirectoryDialogButton";
import {GlobalState} from "state/GlobalState";
import WithMainLayoutPage from "pages/WithMainLayoutPage";
import UploadFileDialogButton from "pages/ObjectStorage/UploadFileDialogButton";
import DeleteObjectStorageDialogButton from "pages/ObjectStorage/DeleteObjectStorageDialogButton";

const useHierarchyObjects = (objects, prefix) => {
    let prefixPath = prefix.join('/');

    if (prefixPath.length > 0) {
        prefixPath += '/';
    }

    return objects
        .filter(object => {
            const pathElements = object.name.split('/').filter(element => element.trim().length > 0);

            if (prefix.length === 0) {
                return pathElements.length === 1;
            }

            const trimmedName = object.name.replace(/\/$/, '');

            return object.name.startsWith(prefixPath)
                && trimmedName !== prefixPath
                && pathElements.length === prefix.length + 1;
        })
        .map(object => {
            let copy = Object.assign({}, object);

            if (copy.type === 'directory') {
                copy.name = object.name.replace(/\/$/, '')
                copy.size = ''
            }

            copy.name = copy.name.substring(prefixPath.length);
            copy.name = copy.name.replace(/^\//, '')

            return copy;
        });
}

const objectTypeIcon = type => {
    if (type === 'directory') {
        return <i className="fas fa-folder" />
    }

    if (type === 'file') {
        return <i className="far fa-file" />
    }

    return type;
}

const ObjectStoragePage = () => {
    const [objects, setObjects] = useState([]);
    const [prefixStack, setPrefixStack] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [dataVersion, setDataVersion] = useState(1);

    useEffect(() => {
        GlobalState.setPageTitle('Object Storage');
        GlobalState.setLayoutPageTitle('Object Storage');
    }, []);

    useEffect(() => {
        setIsLoading(true);

        ObjectStorageApi.objectStorageGetCollection()
            .then(response => {
                setObjects(response.data['hydra:member']);
                setIsLoading(false);
            })
            .catch(ErrorHandling.GlobalStateError)
    }, [dataVersion]);

    const addPrefix = prefix => {
        setPrefixStack(stack => [...stack, prefix]);
    }

    const directoryUp = () => {
        if (prefixStack.length > 0) {
            setPrefixStack(stack => stack.slice(0, -1));
        }
    }

    const createDirectory = name => {
        const prefix = prefixStack.join('/') + '/';
        setIsLoading(true);

        ObjectStorageApi
            .objectStorageCreate({objectStorage: {name: prefix + name, type: 'directory'}})
            .then(() => {
                GlobalState.setSuccess('Directory created successfully');
                setDataVersion(version => version + 1);
            })
            .catch(error => {
                ErrorHandling.GlobalStateError(error);
                setIsLoading(false);
            });
    }

    const createFile = (fileId, fileName) => {
        const prefix = prefixStack.join('/') + '/';
        setIsLoading(true);

        ObjectStorageApi
            .objectStorageCreate({
                objectStorage: {
                    name: prefix + fileName,
                    type: 'file',
                    file: fileId,
                }
            })
            .then(() => {
                GlobalState.setSuccess('File uploaded and created successfully!');
                setDataVersion(version => version + 1);
            })
            .catch(error => {
                ErrorHandling.GlobalStateError(error);
                setIsLoading(false);
            });
    };

    const deleteItem = id => {
        setIsLoading(true);

        ObjectStorageApi.objectStorageDelete({id})
            .then(() => {
                GlobalState.setSuccess('Object storage item successfully deleted!');
                setIsLoading(false);
                setDataVersion(version => version + 1);
            })
            .catch(error => {
                ErrorHandling.GlobalStateError(error);
                setIsLoading(false);
            });
    }

    return <PageCard
        title="Manage object storage elements"
        actions={(<>
            <CreateDirectoryDialogButton
                onDirectoryCreateRequest={name => createDirectory(name)}
            />
            <UploadFileDialogButton
                onFileCreateRequest={createFile}
            />
        </>)}
        loading={isLoading}
    >
        <table className="table table-bordered" id="object-storage-table">
            <thead>
                <tr>
                    <th style={{width: '20px'}}></th>
                    <th>Name</th>
                    <th>Size</th>
                    <th>Uploaded</th>
                    <th>Options</th>
                </tr>
            </thead>
            <tbody>
                {prefixStack.length > 0 ? (
                    <tr>
                        <td>
                            <span onClick={directoryUp}>
                                <i className="fas fa-level-up-alt" />
                            </span>
                        </td>
                        <td>
                            <span onClick={directoryUp}>../</span>
                        </td>
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr>
                ) : ''}
                {useHierarchyObjects(objects, prefixStack).map(object =>
                    <tr key={object.id}>
                        <td>{objectTypeIcon(object.type)}</td>
                        <td>
                            <span onClick={() => addPrefix(object.name)}>{object.name}</span>
                        </td>
                        <td>{object.size}</td>
                        <td>{object.lastModified}</td>
                        <td>
                            <DeleteObjectStorageDialogButton
                                objectStorage={object}
                                onConfirm={deleteItem}
                            />
                        </td>
                    </tr>
                )}
            </tbody>
        </table>
    </PageCard>
};

export default WithMainLayoutPage(ObjectStoragePage);
