import {useEffect, useState} from "react";
import {ErrorHandling} from "../../helpers/error-handling";
import Loader from "../../layouts/main/components/Loader";
import {Link} from "react-router-dom";
import BannerZoneDeleteDialogButton from "./BannerZoneDeleteDialogButton";
import {BannerZoneApi} from "../../HtvApi/Api";

export default function BannerZoneList() {
    const [zones, setZones] = useState(null);
    const [dataVersion, setDataVersion] = useState(0);

    useEffect(() => {
        BannerZoneApi.bannerZoneGetCollection()
            .then(response => {
                setZones(response.data['hydra:member']);
            })
            .catch(ErrorHandling.GlobalStateError);
    }, [dataVersion]);

    return <>
        <table className="table table-hover text-nowrap" id="banner-zones">
            <thead>
                <tr>
                    <th>Key</th>
                    <th>Description</th>
                    <th>Image size</th>
                    <th>Options</th>
                </tr>
            </thead>
            <tbody>
            {zones ? zones.map(zone => {
                return <tr key={zone.id}>
                    <td className="align-middle">{zone.key}</td>
                    <td className="align-middle">{zone.description}</td>
                    <td className="align-middle">{zone.width} x {zone.height}</td>
                    <td className="align-middle">
                        <Link to={`/banners/edit-zone/${zone.id}`} className="btn btn-sm btn-warning">
                            <i className="fas fa-pencil-alt" /> Edit zone
                        </Link>
                        &nbsp;
                        <Link to={`/banners/zone/${zone.id}`} className="btn btn-sm btn-primary">
                            <i className="fas fa-images" /> Images
                        </Link>
                        &nbsp;
                        <BannerZoneDeleteDialogButton
                            id={zone.id}
                            onConfirm={() => setDataVersion(version => version + 1)}
                        />
                    </td>
                </tr>
            }) : (<tr><td colSpan={4}>No results</td></tr>)}
            </tbody>
        </table>
        {zones === null ? <Loader /> : ''}
    </>;
}
