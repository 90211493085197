import WithMainLayoutPage from "../../../WithMainLayoutPage";
import PageCard from "../../../../layouts/main/components/PageCard";
import {useEffect, useState} from "react";
import HtvApi from "../../../../htv-api/HtvApi";
import {GlobalState} from "../../../../state/GlobalState";
import {useNavigate, useParams} from "react-router-dom";
import Loader from "../../../../layouts/main/components/Loader";
import {ErrorHandling} from "../../../../helpers/error-handling";
import VideoCategoriesEditForm from "./VideoCategoriesEditForm";

const parseTitleTranslations = (translations) => {
    let parsed = {};

    translations.forEach((item) => {
        parsed[item.locale] = item.value;
    });

    return parsed;
}

const VideoCategoriesEditPage = () => {
    console.log('--- RENDERING: Video category edit page ---');

    let {id} = useParams();
    const [categoryData, setCategoryData] = useState(undefined);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        HtvApi.VideoCategories.Get(id).then(response => {
            setCategoryData({
                titleTranslations: parseTitleTranslations(response.titleTranslations),
                title: response.title,
                slug: response.slug,
                status: response.status,
                parentCategory: response?.parent?.id || null,
            });
            setIsLoading(false);
        }).catch(ErrorHandling.GlobalStateError);
    }, [id]);

    const navigate = useNavigate();

    const onSubmit = (data) => {
        setIsLoading(true);

        HtvApi.VideoCategories.Edit(id,{
            titleTranslations: data.titleTranslations,
            slug: data.slug,
            status: data.status,
        }).then((response) => {
            GlobalState.setSuccess('Successful operation!', 'Video category ' + response.title + ' saved successfully!');
            navigate('/videos/categories', {state: {keepMessages: true}});
        }).catch(error => {
            setIsLoading(false);
            ErrorHandling.GlobalStateError(error);
        });
    }

    return (
        <PageCard title={'Edit video category' + (categoryData ? ': ' + categoryData.title : '')}>
            {isLoading
                ? <Loader />
                : <VideoCategoriesEditForm
                    categoryData={categoryData}
                    onSubmit={onSubmit}
                />
            }
        </PageCard>
    );
};

export default WithMainLayoutPage(VideoCategoriesEditPage);