export class NewsImageModel {
    constructor(image) {
        if (image instanceof NewsImageModel) {
            this.label = image.label;
            this.fileID = image.fileID;
            this.fileIRI = image.fileIRI;
            this.url = image.url;
            return;
        }

        this.label = image?.label;
        this.file = image?.file;
        this.url = image?.url;
    }

    set file(value) {
        if (!value) {
            this.fileIRI = undefined;
            this.fileID = undefined;
            return;
        }

        if (value.startsWith('/')) {
            this.fileIRI = value;
            this.fileID = value.substring(value.lastIndexOf('/') + 1);
            return;
        }

        this.fileID = value;
        this.fileIRI = '/files/' + this.fileID;
    }

    toImageRequest() {
        return {
            label: this.label,
            file: this.fileIRI,
        }
    }
}