import './VideoPage.css';
import {useCallback, useEffect, useState} from 'react';
import 'datatables.net';
import 'datatables.net-bs4';
import HtvApi from "../../htv-api/HtvApi";
import dayjs from "dayjs";
import {Link} from "react-router-dom";
import WithMainLayoutPage from "../WithMainLayoutPage";
import {GlobalState} from "../../state/GlobalState";
import DataTable from "../../layouts/main/components/DataTable/DataTable";
import VideoDeleteDialogButton from "./VideoDeleteDialogButton";
import {ErrorHandling} from "../../helpers/error-handling";
import VideoInformationDialogButton from "./VideoInformationDialogButton";
import PageCard from "../../layouts/main/components/PageCard";
import ActionButton from "../../layouts/main/components/Button/ActionButton";

const VideosPage = () => {
    console.log('--- RENDERING: Video page ---');
    const [, setDataVersion] = useState(1);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        GlobalState.setPageTitle('Videos management');
        GlobalState.setLayoutPageTitle('Videos management');
    }, []);

    const fetchTableData = useCallback((page, perPage, orderByColumn, orderDescending, search) => {
        return HtvApi.Videos.List(page, perPage, orderByColumn, orderDescending, search)
            .catch((error) => {
                ErrorHandling.GlobalStateError(error)
                throw error;
            });
    }, []);

    const updateVideoThumbnail = useCallback(videoId => {
        setLoading(true);

        HtvApi.Videos.UpdateThumbnails(videoId)
            .then(() => {
                GlobalState.setSuccess('Successful operation!', 'Video thumbnails updated!');
            })
            .catch(error => {
                ErrorHandling.GlobalStateError(error)
            })
            .then(() => {
                setLoading(false);
            });
    }, []);

    return (
        <PageCard
            title='List of videos'
            actions={(
                <Link to="/videos/add" className="btn btn-sm btn-success">
                    <i className="fas fa-plus" />&nbsp;&nbsp;&nbsp;Add new
                </Link>
            )}
            loading={loading}
        >
            <DataTable
                fetchData={fetchTableData}
                defaultSort={{column: 'createdAt', desc: true}}
                columns={[
                    {
                        label: 'Title',
                        field: 'title',
                        sortable: true,
                        searchable: true,
                    },
                    {
                        label: 'Link',
                        field: 'slug',
                        sortable: true,
                        searchable: true,
                    },
                    {
                        label: 'Duration',
                        field: 'duration',
                        sortable: true,
                    },
                    {
                        label: 'Status',
                        field: 'status',
                        sortable: true,
                        searchable: true,
                    },
                    {
                        label: 'Created at',
                        field: 'createdAt',
                        render: date => dayjs(date).format('D MMM YYYY H:mm:ss'),
                        sortable: true,
                    },
                    {
                        label: 'Watched',
                        field: 'watchCount',
                        sortable: true,
                    },
                    {
                        label: 'Options',
                        field: 'options',
                        dataField: null,
                        render: row => {
                            return (
                                <>
                                    <VideoInformationDialogButton videoId={row.id} />
                                    &nbsp;
                                    <Link className="btn btn-warning btn-sm" to={'/videos/edit/' + row.id}>
                                        <i className="fas fa-pencil-alt" /> Edit
                                    </Link>
                                    &nbsp;
                                    <ActionButton actions={[
                                        {
                                            name: 'Update thumbnails',
                                            onClick: () => updateVideoThumbnail(row.id)
                                        }
                                    ]} />
                                    &nbsp;
                                    <VideoDeleteDialogButton
                                        videoId={row.id}
                                        onVideoDeleted={() => setDataVersion(version => version + 1)}
                                    />
                                </>
                            );
                        },
                    }
                ]}
            />
        </PageCard>
    );
};

export default WithMainLayoutPage(VideosPage);
