import WithMainLayoutPage from "pages/WithMainLayoutPage";
import {GlobalState} from "state/GlobalState";
import PageCard from "layouts/main/components/PageCard";
import {useEffect, useState} from "react";
import {SubscriptionApi} from "HtvApi/Api";
import {ErrorHandling} from "helpers/error-handling";

const SubscriptionsPage = () => {
    useEffect(() => {
        GlobalState.setPageTitle('Subscriptions');
        GlobalState.setLayoutPageTitle('Subscriptions');
    }, []);

    const [isLoading, setIsLoading] = useState(true);
    const [subscriptions, setSubscriptions] = useState([]);

    useEffect(() => {
        SubscriptionApi.subscriptionGetCollection()
            .then(response => {
                setSubscriptions(response.data['hydra:member']);
                setIsLoading(false);
            })
            .catch(ErrorHandling.GlobalStateError);
    }, []);

    return <PageCard
        title="View active subscriptions"
        loading={isLoading}
    >
        <table className="table table-bordered" id="subscriptions-table">
            <thead>
                <tr>
                    <th>Name</th>
                    <th>E-mail</th>
                    <th>Created at</th>
                    <th>Options</th>
                </tr>
            </thead>
            <tbody>
                {subscriptions.map(subscription =>
                    <tr key={subscription.id}>
                        <td>{subscription.name}</td>
                        <td>{subscription.email}</td>
                        <td>{subscription.createdAt}</td>
                        <td></td>
                    </tr>
                )}
            </tbody>
        </table>
    </PageCard>
}

export default WithMainLayoutPage(SubscriptionsPage);
