import {observer} from "mobx-react";
import {useEffect} from "react";
import WithMainLayoutPage from "../WithMainLayoutPage";
import {GlobalState} from "../../state/GlobalState";

const HomePage = observer( () => {
    console.log('Rendering home page...');
    useEffect(() => {
        GlobalState.setPageTitle('Dashboard');
        GlobalState.setLayoutPageTitle('Dashboard');
    }, []);

    return (<div> Home page </div>);
})

export default WithMainLayoutPage(HomePage);
