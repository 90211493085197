import Locales from "../../../Locales";
import {Tab, Tabs} from "react-bootstrap";
import FormTextField from "../../../layouts/main/components/Form/FormTextField";
import {useCallback, useRef} from "react";
import FormChoice from "../../../layouts/main/components/Form/FormChoice";
import {Link} from "react-router-dom";

const NewsCategoryForm = ({initialData = {}, onSubmit}) => {
    const data = useRef({
        status: initialData.status || 'active',
        slug: initialData.slug || '',
        titleTranslations: initialData.titleTranslations || {},
    });

    const onSubmitFn = event => {
        event.preventDefault();
        onSubmit(data.current);
    }

    const i18nTabs = [];

    for (const [locale, localeTitle] of Object.entries(Locales)) {
        i18nTabs.push(
            <Tab eventKey={locale} title={localeTitle} key={locale}>
                <div className="mt-3">
                    <FormTextField
                        label={'Title (' + localeTitle + ')'}
                        // eslint-disable-next-line react-hooks/rules-of-hooks
                        onChange={useCallback(
                            value => data.current.titleTranslations[locale] = value, [locale]
                        )}
                        initialValue={data.current.titleTranslations[locale] || ''}
                    />
                </div>
            </Tab>
        )
    }

    return (
        <form onSubmit={onSubmitFn}>
            <Tabs>
                {i18nTabs}
            </Tabs>

            <FormTextField
                label='Link'
                onChange={useCallback(value => data.current.slug = value, [])}
                initialValue={data.current.slug || ''}
            />

            <FormChoice
                name="status"
                label="Status"
                options={{
                    active: {
                        label: 'Active',
                        color: 'green',
                        icon: 'fas fa-check-circle',
                    },
                    inactive: {
                        label: 'Inactive',
                        color: 'red',
                        icon: 'fas fa-times-circle',
                    },
                }}
                initialValue={data.current.status || 'active'}
                onChange={useCallback(value => data.current.status = value, [])}
            />

            <div>
                <button
                    type="submit"
                    className="btn btn-success"
                >
                    Save category
                </button>
                <Link
                    to="/news/categories"
                    type="button"
                    className="btn btn-danger float-right"
                >
                    Cancel (changes will be lost)
                </Link>
            </div>
        </form>
    );
}

export default NewsCategoryForm;
