import {useEffect, useState} from "react";
import {ContactMessageApi} from "HtvApi/Api";
import {ErrorHandling} from "helpers/error-handling";
import Loader from "layouts/main/components/Loader";
import {Link} from "react-router-dom";

const lineStyle = message => {
    if (message.isSeen) {
        return {}
    }

    return {background: '#AFA'}
}

const ContactMessagesList = () => {
    const [messages, setMessages] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        ContactMessageApi.contactMessageGetCollection()
            .then(response => {
                setMessages(response.data['hydra:member']);
            })
            .catch(ErrorHandling.GlobalStateError)
            .then(() => setLoading(false))
    }, []);

    if (loading) {
        return <Loader />
    }

    return <table className="table table-bordered" id="contact-messages-table">
        <thead>
        <tr>
            <th>From</th>
            <th>Subject</th>
            <th>Submitted at</th>
            <th>Options</th>
        </tr>
        </thead>
        <tbody>
            {messages.map(message =>
                <tr key={message.id} style={lineStyle(message)}>
                    <td className="align-middle">{message.name}</td>
                    <td className="align-middle">{message.subject}</td>
                    <td className="align-middle">{message.createdAt}</td>
                    <td>
                        <Link
                            to={`/contact-messages/${message.id}`}
                            className="btn btn-primary"
                            data-id={message.id}
                        >
                            <i className="far fa-eye" /> View
                        </Link>
                        <button>Delete</button>
                    </td>
                </tr>
            )}
        </tbody>
    </table>
}

export default ContactMessagesList;
