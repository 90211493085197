export default class VideoThumbnailResolutions
{
    constructor(htvApi) {
        this.htvApi = htvApi;
    }

    List() {
        return this
            .htvApi
            .Call(this.htvApi.url + '/video-thumbnail-resolutions');
    }

    Get(id) {
        return this.htvApi.Call(this.htvApi.url + '/video-thumbnail-resolutions/' + id);
    }

    Create(width, height) {
        const requestData = {
            width: parseInt(width),
            height: parseInt(height),
        };

        return this.htvApi.Call(
            this.htvApi.url + '/video-thumbnail-resolutions',
            {
                method: 'POST',
                body: JSON.stringify(requestData),
            }
        );
    }

    Delete(id) {
        return this.htvApi.Call(this.htvApi.url + '/video-thumbnail-resolutions/' + id, {method: 'DELETE'});
    }
}
