import PageCard from "layouts/main/components/PageCard";
import {GlobalState} from "state/GlobalState";
import {useEffect, useState} from "react";
import WithMainLayoutPage from "pages/WithMainLayoutPage";
import {ContactMessageApi} from "HtvApi/Api";
import {ErrorHandling} from "helpers/error-handling";
import {Link, useParams} from "react-router-dom";

const ContactMessageReadPage = () => {
    useEffect(() => {
        GlobalState.setPageTitle('Contact messages');
        GlobalState.setLayoutPageTitle('Contact messages');
    }, []);

    const {id} = useParams();
    const [message, setMessage] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        ContactMessageApi.contactMessageGet({id})
            .then(response => {
                setMessage(response.data);
            })
            .catch(ErrorHandling.GlobalStateError)
            .then(() => setLoading(false))
    }, [id]);

    return <PageCard
        title={`Reading message from: ${loading ? '...' : message.name}`}
        loading={loading}
        actions={(
            <Link to="/contact-messages" className="btn btn-sm btn-danger">
                <i className="fas fa-arrow-left" />&nbsp;&nbsp;&nbsp;Back to message list
            </Link>
        )}
    >
        {loading ? '' : (
            <dl>
                <dt>From</dt>
                <dd className="ml-3">{message.name}</dd>
                <dt>Email</dt>
                <dd className="ml-3">{message.email}</dd>
                <dt>Date</dt>
                <dd className="ml-3">{message.createdAt}</dd>
                <dt>IP</dt>
                <dd className="ml-3">{message.fromIp}</dd>
                <dt>Subject</dt>
                <dd className="ml-3">{message.subject}</dd>
                <dt>Message</dt>
                <dd className="ml-3">{message.content}</dd>
            </dl>
        )}
    </PageCard>
}

export default WithMainLayoutPage(ContactMessageReadPage);
