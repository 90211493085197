import {useCallback, useState} from "react";
import uniqueId from "../../../../helpers/unique-id";

const FormSelectMultiple = ({
    label,
    options,
    selected,
    onChange,
    testId = null,
}) => {
    const [id] = useState(uniqueId());
    const [value, setValue] = useState(selected);
    const onChangeFn = useCallback(e => {
        const categories = Array.from(e.target.selectedOptions, option => option.value);
        setValue(categories);
        onChange(categories);
    }, [onChange])

    return (
        <div className="form-group row">
            <label className="col-sm-2 col-form-label" htmlFor={id}>{label}:</label>
            <div className="col-sm-10">
                <select
                    id={id}
                    multiple={true}
                    className="form-control"
                    style={{height: '250px'}}
                    value={value}
                    onChange={onChangeFn}
                    data-testid={testId}
                >
                    {options.map(option => (
                        <option key={option.value} value={option.value}>{option.label}</option>
                    ))}
                </select>
            </div>
        </div>
    );
};

export default FormSelectMultiple;
