import WithMainLayoutPage from "../WithMainLayoutPage";
import {useEffect, useState} from "react";
import HtvApi from "../../htv-api/HtvApi";
import {ErrorHandling} from "../../helpers/error-handling";
import VideoThumbnailResolutionDeleteDialogButton from "./VideoThumbnailResolutionDeleteDialogButton";
import {GlobalState} from "../../state/GlobalState";
import PageCard from "../../layouts/main/components/PageCard";

const VideoThumbnailResolutionsPage = () => {
    useEffect(() => {
        GlobalState.setPageTitle('Video thumbnail resolutions');
        GlobalState.setLayoutPageTitle('Videos management');
    }, []);

    const [resolutionsList, setResolutionsList] = useState([]);
    const [dataVersion, setDataVersion] = useState(1);
    const [width, setWidth] = useState('');
    const [height, setHeight] = useState('');

    useEffect(() => {
        HtvApi.VideoThumbnailResolutions.List().then(response => {
            setResolutionsList(response['hydra:member']);
        }).catch(ErrorHandling.GlobalStateError)
    }, [dataVersion]);

    let resolutionRows = [];

    for (const resolution of resolutionsList) {
        resolutionRows.push(
            <tr key={resolution['id']}>
                <td>{resolution['width']}</td>
                <td>{resolution['height']}</td>
                <td>
                    <VideoThumbnailResolutionDeleteDialogButton
                        thumbnailId={resolution['id']}
                        onDeleted={() => setDataVersion(version => version + 1)}
                    />
                </td>
            </tr>
        )
    }

    const onSubmit = (e) => {
        e.preventDefault();
        HtvApi.VideoThumbnailResolutions.Create(width, height).then(() => {
            GlobalState.setSuccess('Resolution added');
            setWidth('');
            setHeight('');
            setDataVersion(version => version + 1);
        }).catch(ErrorHandling.GlobalStateError);
    };

    return (
        <PageCard title='Video thumbnail resolutions'>
            <form onSubmit={onSubmit}>
                <table className="table table-hover text-nowrap">
                    <thead>
                    <tr>
                        <th>Width</th>
                        <th>Height</th>
                        <th>Options</th>
                    </tr>
                    </thead>
                    <tbody>

                    <tr key="add-new">
                        <td>
                            <input
                                className="form-control form-control-sm"
                                value={width}
                                onChange={(e) => setWidth(e.target.value)}
                            />
                        </td>
                        <td>
                            <input
                                className="form-control form-control-sm"
                                value={height}
                                onChange={(e) => setHeight(e.target.value)}
                            />
                        </td>
                        <td>
                            <button className="btn btn-sm btn-success" type="submit">
                                <i className="fas fa-plus" /> Add
                            </button>
                        </td>
                    </tr>

                    {resolutionRows}
                    </tbody>
                </table>
            </form>
        </PageCard>
    );
}

export default WithMainLayoutPage(VideoThumbnailResolutionsPage);
