import {
    BannerApi,
    BannerZoneApi,
    Configuration,
    ContactMessageApi,
    FileApi,
    NewsApi,
    NewsCategoryApi,
    ObjectStorageApi,
    SubscriptionApi,
    PreferenceApi,
    VideoApi,
    VideoCategoryApi
} from "api-client-typescript-axios/dist";
import {autorun} from "mobx";
import {AuthenticationState} from "state/AuthenticationState";

const ApiConfiguration = new Configuration({
    basePath: process.env.REACT_APP_HTV_API_URL,
});

autorun(() => {
    ApiConfiguration.accessToken = AuthenticationState.accessToken;
})

const BannerApiObject = new BannerApi(ApiConfiguration);
const BannerZoneApiObject = new BannerZoneApi(ApiConfiguration);
const VideoApiObject = new VideoApi(ApiConfiguration);
const VideoCategoryApiObject = new VideoCategoryApi(ApiConfiguration);
const NewsApiObject = new NewsApi(ApiConfiguration);
const NewsCategoryApiObject = new NewsCategoryApi(ApiConfiguration);
const PreferenceApiObject = new PreferenceApi(ApiConfiguration);
const ObjectStorageApiObject = new ObjectStorageApi(ApiConfiguration);
const FileApiObject = new FileApi(ApiConfiguration);
const ContactMessageApiObject = new ContactMessageApi(ApiConfiguration);
const SubscriptionApiObject = new SubscriptionApi(ApiConfiguration);

export const VideoThumbnailUrl = ({id, width, height}) => {
    return process.env.REACT_APP_HTV_API_URL + `/videos/${id}/thumbnail/${width}/${height}`;
}

export const SetTranslation = (translations, locale, value) => {
    const translation = translations.find(t => t.locale === locale);

    if (translation) {
        translation.value = value;
    } else {
        translations.push({locale, value});
    }
}

export const GetTranslation = (translations, locale) => {
    return translations.find(t => t.locale === locale)?.value ?? '';
}

export {
    BannerApiObject as BannerApi,
    BannerZoneApiObject as BannerZoneApi,
    VideoApiObject as VideoApi,
    VideoCategoryApiObject as VideoCategoryApi,
    NewsApiObject as NewsApi,
    NewsCategoryApiObject as NewsCategoryApi,
    PreferenceApiObject as PreferenceApi,
    ObjectStorageApiObject as ObjectStorageApi,
    FileApiObject as FileApi,
    ContactMessageApiObject as ContactMessageApi,
    SubscriptionApiObject as SubscriptionApi,
}
