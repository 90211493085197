import {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import HtvApi from "../../../../htv-api/HtvApi";
import {GlobalState} from "../../../../state/GlobalState";
import {ErrorHandling} from "../../../../helpers/error-handling";
import PageCard from "../../../../layouts/main/components/PageCard";
import WithMainLayoutPage from "../../../WithMainLayoutPage";
import NewsCategoryForm from "../NewsCategoryForm";

const NewsCategoriesEditPage = () => {
    useEffect(() => {
        GlobalState.setPageTitle('News management');
        GlobalState.setLayoutPageTitle('News management');
    }, []);

    let {id} = useParams();
    const [isLoading, setIsLoading] = useState(true);
    const [initialData, setInitialData] = useState({});
    const navigate = useNavigate();

    useEffect(() => {
        HtvApi.NewsCategories.Get(id).then(response => {
            let translations = {};

            response.titleTranslations.forEach(translation => {
                translations[translation.locale] = translation.value;
            });

            setInitialData({
                slug: response.slug,
                status: response.status,
                titleTranslations: translations,
            });
            setIsLoading(false);
        });
    }, [id]);

    const onSubmit = data => {
        setIsLoading(true);

        HtvApi.NewsCategories.Edit(id, {
            titleTranslations: data.titleTranslations,
            slug: data.slug,
            status: data.status,
        }).then((response) => {
            GlobalState.setSuccess('Successful operation!', 'News category ' + response.title + ' updated successfully!');
            navigate('/news/categories', {state: {keepMessages: true}});
        }).catch(error => {
            setIsLoading(false);
            ErrorHandling.GlobalStateError(error);
        });
    }

    return (
        <PageCard title='Edit news category' loading={isLoading}>
            {isLoading ? '' : <NewsCategoryForm initialData={initialData} onSubmit={onSubmit} />}
        </PageCard>
    );
};

export default WithMainLayoutPage(NewsCategoriesEditPage);