import WithMainLayoutPage from "../../WithMainLayoutPage";
import PageCard from "../../../layouts/main/components/PageCard";
import {Link, useNavigate, useParams} from "react-router-dom";
import {useEffect, useRef, useState} from "react";
import {GlobalState} from "../../../state/GlobalState";
import {ErrorHandling} from "../../../helpers/error-handling";
import Loader from "../../../layouts/main/components/Loader";
import {Map} from "immutable";
import {NewsImageModel} from "../../../models/NewsImage";
import NewsForm from "../NewsForm";
import {NewsApi, SetTranslation} from "../../../HtvApi/Api";

const NewsEditPage = () => {
    useEffect(() => {
        GlobalState.setPageTitle('News management');
        GlobalState.setLayoutPageTitle('News management');
    }, []);

    let {id} = useParams();
    const navigate = useNavigate();

    const data = useRef({
        titleTranslations: [],
        subTitleTranslations: [],
        contentTranslations: [],
        slug: '',
        source: '',
        status: 'public',
        vimeoVideo: null,
        displayDate: new Date(),
        images: Map(),
        categories: [],
    });

    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        NewsApi.newsGet({id})
            .then(response => {
                data.current.slug = response.data['slug'];
                data.current.source = response.data['source'];
                data.current.status = response.data['status'];
                data.current.vimeoVideo = response.data['vimeoVideo'];
                data.current.displayDate = response.data['displayDate'];
                data.current.images = Map(response.data['images'].map(image => [image.label, new NewsImageModel(image)]));
                data.current.categories = response.data['categories'].map(category => category['@id']);

                response.data['titleTranslations'].forEach(
                    t => SetTranslation(data.current.titleTranslations, t.locale, t.value)
                );
                response.data['subTitleTranslations'].forEach(
                    t => SetTranslation(data.current.subTitleTranslations, t.locale, t.value)
                );
                response.data['contentTranslations'].forEach(
                    t => SetTranslation(data.current.contentTranslations, t.locale, t.value)
                );

                setIsLoading(false);
            })
    }, [id]);

    const onSubmit = formData => {
        setIsLoading(true);

        formData.images.forEach(image => {
            if (!image.fileIRI) {
                ErrorHandling.GlobalStateError(new Error('Invalid or missing image'));
                return false;
            }
        });

        let newsData = {
            ...formData,
            images: [
                ...formData.images
                    .filter(image => image.url === undefined)
                    .map(image => image.toImageRequest())
                    .values()
            ],
        }

        NewsApi.newsUpdate({id, newsNewsUpdate: newsData})
            .then(response => {
                GlobalState.setSuccess('Successful operation!', 'News "' + response.data.title + '" updated successfully!');
                navigate('/news', {state: {keepMessages: true}});
            })
            .catch(error => {
                ErrorHandling.GlobalStateError(error);
                setIsLoading(false);
            });
    };

    return (
        <PageCard
            title='Edit news'
            actions={(
                <Link to="/news" className="btn btn-sm btn-danger">
                    <i className="fas fa-arrow-left" />&nbsp;&nbsp;&nbsp;Back to news list
                </Link>
            )}
        >
            {isLoading ? <Loader /> : <NewsForm data={data.current} onSubmit={onSubmit} />}
        </PageCard>
    );
};

export default WithMainLayoutPage(NewsEditPage);
