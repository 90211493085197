import {useState} from "react";
import UploadFileDialog from "pages/ObjectStorage/UploadFileDialog";

const UploadFileDialogButton = ({onFileCreateRequest}) => {
    const [modalOpen, setModalOpen] = useState(false);

    const onCreateSubmit = (fileId, fileName) => {
        onFileCreateRequest(fileId, fileName);
        setModalOpen(false);
    };

    return <>
        <button className="btn btn-sm btn-success ml-2" onClick={() => setModalOpen(true)}>
            <i className="fas fa-upload" />&nbsp;&nbsp;&nbsp;Upload new file
        </button>
        {modalOpen ?
            <UploadFileDialog
                onCancel={() => setModalOpen(false)}
                onSubmit={onCreateSubmit}
            />
        : ''}
    </>;
}

export default UploadFileDialogButton;
