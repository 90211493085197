import HtvApi from "../../../htv-api/HtvApi";
import {ErrorHandling} from "../../../helpers/error-handling";
import {useState} from "react";
import LoadingDialog from "../../../layouts/main/components/LoadingDialog";
import ConfirmDeletionDialog from "../../../layouts/main/components/ConfirmDeletionDialog";
import {GlobalState} from "../../../state/GlobalState";

const VideoCategoriesDeleteButton = ({id, onConfirm}) => {
    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState(null);

    const closeDialog = () => {
        setIsLoading(false);
        setData(null);
    };

    const confirmDelete = () => {
        HtvApi.VideoCategories.Delete(id).then(() => {
            GlobalState.setSuccess('Successful operation!', 'Video category deleted successfully!');
            closeDialog();
            onConfirm && onConfirm();
        }).catch(ErrorHandling.GlobalStateError);
    };

    const showConfirmDialog = () => {
        setIsLoading(true);

        HtvApi.VideoCategories.Get(id).then(response => {
            setData({
                title: response.title,
            });
            setIsLoading(false);
        }).catch(error => {
            ErrorHandling.GlobalStateError(error);
            closeDialog();
        });
    };

    const button = (
        <button className="btn btn-xs btn-danger float-right" onClick={() => showConfirmDialog()}>
            <i className="far fa-trash-alt" /> Delete
        </button>
    );

    if (isLoading) {
        return (
            <>
                {button}
                <LoadingDialog onCancel={closeDialog}/>
            </>
        );
    }

    if (data) {
        return (
            <>
                {button}
                <ConfirmDeletionDialog
                    title={'Confirm deletion of video category: ' + data.title}
                    description={''}
                    onCancel={() => closeDialog()}
                    onConfirm={() => confirmDelete()}
                />
            </>
        );
    }

    return button;
};

export default VideoCategoriesDeleteButton;
