import {useState} from "react";
import HtvApi from "../../htv-api/HtvApi";
import {useLocation, useNavigate} from "react-router-dom";
import {Alert} from "@mui/material";
import {AuthenticationState} from "../../state/AuthenticationState";
import Loader from "../main/components/Loader";

export default function LoginForm() {
    document.body.className = 'hold-transition login-page';

    const navigate = useNavigate();
    const location = useLocation();

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [code, setCode] = useState('');
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const handleSubmit = (event) => {
        event.preventDefault();
        setError(null);
        setIsLoading(true);

        HtvApi
            .Login(email, password, code)
            .then(response => {
                AuthenticationState.setAccessToken(response.token);

                navigate(location.state.redirectTo || '/');
            })
            .catch(error => {
                setError(error);
                setIsLoading(false);
            });
    };

    return (
        <form method="post" onSubmit={handleSubmit}>
            {error ? <Alert severity="error">{error.title}<br />{error.description}</Alert> : ''}
            <div className="input-group mb-3 mt-3">
                <input
                    type="email"
                    className="form-control"
                    placeholder="Email"
                    autoComplete="email"
                    value={email}
                    required
                    onChange={e => setEmail(e.target.value)}
                />
                <div className="input-group-append">
                    <div className="input-group-text">
                        <span className="fas fa-envelope" />
                    </div>
                </div>
            </div>
            <div className="input-group mb-3">
                <input
                    type="password"
                    className="form-control"
                    placeholder="Password"
                    autoComplete="current-password"
                    value={password}
                    required
                    onChange={e => setPassword(e.target.value)}
                />
                <div className="input-group-append">
                    <div className="input-group-text">
                        <span className="fas fa-lock" />
                    </div>
                </div>
            </div>
            <div className="input-group mb-3">
                <input
                    type="text"
                    className="form-control"
                    placeholder="Security code"
                    autoComplete="one-time-code"
                    value={code}
                    required
                    onChange={e => setCode(e.target.value)}
                />
                <div className="input-group-append">
                    <div className="input-group-text">
                        <span className="fas fa-key" />
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-8" />
                <div className="col-4">
                    <button type="submit" className="btn btn-primary btn-block">Sign In</button>
                </div>
            </div>
            {isLoading ? <Loader /> : ''}
        </form>
    );
}
