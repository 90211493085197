import {Button, Modal} from "react-bootstrap";
import LoadingDialog from "./LoadingDialog";

const ConfirmDeletionDialog = ({title, description, onCancel, onConfirm, isLoading = false}) => {
    if (isLoading) {
        return <LoadingDialog onCancel={onCancel} />
    }

    return (
        <Modal
            show={true}
            onHide={() => onCancel()}
            size="xl"
            aria-labelledby="contained-modal-title-vcenter-delete"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter-delete">{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {description}
            </Modal.Body>
            <Modal.Footer>
                <Button
                    variant="secondary"
                    onClick={() => onCancel()}
                    data-testid="deletion-dialog-cancel-button"
                    className="mr-auto"
                >
                    Cancel
                </Button>
                <Button variant="danger" onClick={() => onConfirm()} id="deletion-dialog-confirm-button">
                    DELETE
                </Button>
            </Modal.Footer>
        </Modal>
    )
};

export default ConfirmDeletionDialog;
