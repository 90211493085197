import './App.css';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import LoginLayout from "./layouts/login/LoginLayout";
import MainLayout from "./layouts/main/MainLayout";
import HomePage from "./pages/home/HomePage";
import VideosPage from "./pages/videos/VideoPage";
import VideoAddPage from "./pages/videos/add/VideoAddPage";
import VideoEditPage from "./pages/videos/edit/VideoEditPage";
import VideoThumbnailResolutionsPage from "./pages/videos/VideoThumbnailResolutionsPage";
import VideoCategoriesPage from "./pages/videos/categories/VideoCategoriesPage";
import VideoCategoriesAddPage from "./pages/videos/categories/VideoCategoriesAddPage";
import VideoCategoriesEditPage from "./pages/videos/categories/edit/VideoCategoriesEditPage";
import VideoRecommendedPage from "./pages/videos/recommended/VideoRecommendedPage";
import NewsPage from "./pages/news/NewsPage";
import NewsCreatePage from "./pages/news/create/NewsCreatePage";
import NewsEditPage from "./pages/news/edit/NewsEditPage";
import NewsImageSizesPage from "./pages/news/image-sizes/NewsImageSizesPage";
import NewsCategoriesPage from "./pages/news/categories/NewsCategoriesPage";
import NewsCategoriesCreatePage from "./pages/news/categories/create/NewsCategoriesCreatePage";
import NewsCategoriesEditPage from "./pages/news/categories/edit/NewsCategoriesEditPage";
import NewsRecommendedPage from "./pages/news/recommended/NewsRecommendedPage";
import BannerZonePage from "./pages/banners/BannerZonesPage";
import BannerZoneCreatePage from "./pages/banners/create/BannerZoneCreatePage";
import BannerZoneUpdatePage from "./pages/banners/update/BannerZoneUpdatePage";
import BannersListingPage from "./pages/banners/BannersListingPage";
import BannerCreatePage from "./pages/banners/zone/banners/BannerCreatePage";
import BannerEditPage from "./pages/banners/zone/banners/BannerEditPage";
import SettingsPage from "./pages/settings/SettingsPage";
import ObjectStoragePage from "pages/ObjectStorage/ObjectStoragePage";
import ContactMessagesPage from "pages/ContactMessages/ContactMessagesPage";
import ContactMessageReadPage from "pages/ContactMessages/ContactMessageReadPage";
import SubscriptionsPage from "pages/Subscriptions/SubscriptionsPage";

export default function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/login" element={<LoginLayout />} />
        <Route path="/" element={<MainLayout />}>
          <Route index element={<HomePage />} />

          <Route path="videos" element={<VideosPage />} />
          <Route path="videos/add" element={<VideoAddPage />} />
          <Route path="videos/edit/:id" element={<VideoEditPage />} />
          <Route path="videos/thumbnail-resolutions" element={<VideoThumbnailResolutionsPage />} />
          <Route path="videos/categories" element={<VideoCategoriesPage />} />
          <Route path="videos/categories/add" element={<VideoCategoriesAddPage />} />
          <Route path="videos/categories/edit/:id" element={<VideoCategoriesEditPage />} />
          <Route path="videos/recommended" element={<VideoRecommendedPage />} />

          <Route path="news" element={<NewsPage />} />
          <Route path="news/create" element={<NewsCreatePage />} />
          <Route path="news/edit/:id" element={<NewsEditPage />} />
          <Route path="news/image-sizes" element={<NewsImageSizesPage />} />
          <Route path="news/categories" element={<NewsCategoriesPage />} />
          <Route path="news/categories/create" element={<NewsCategoriesCreatePage />} />
          <Route path="news/categories/edit/:id" element={<NewsCategoriesEditPage />} />
          <Route path="news/recommended" element={<NewsRecommendedPage />} />

          <Route path="banners" element={<BannerZonePage />} />
          <Route path="banners/create-zone" element={<BannerZoneCreatePage />} />
          <Route path="banners/edit-zone/:id" element={<BannerZoneUpdatePage />} />
          <Route path="banners/zone/:id" element={<BannersListingPage />} />
          <Route path="banners/zone/:id/create-banner" element={<BannerCreatePage />} />
          <Route path="banners/edit/:id" element={<BannerEditPage />} />

          <Route path="object-storage" element={<ObjectStoragePage />} />

          <Route path="contact-messages" element={<ContactMessagesPage />} />
          <Route path="contact-messages/:id" element={<ContactMessageReadPage />} />

          <Route path="subscriptions" element={<SubscriptionsPage />} />

          <Route path="settings" element={<SettingsPage />} />

          <Route path="*" element={<div> PAGE NOT FOUND </div>} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}
