import WithMainLayoutPage from "../../../WithMainLayoutPage";
import PageCard from "layouts/main/components/PageCard";
import BackButton from "layouts/main/components/Button/BackButton";
import {useEffect, useState} from "react";
import {GlobalState} from "state/GlobalState";
import {useNavigate, useParams} from "react-router-dom";
import {ErrorHandling} from "helpers/error-handling";
import BannerForm from "./BannerForm";
import {BannerApi} from "HtvApi/Api";
import {uriToId} from "helpers/functions";

const BannerEditPage = () => {
    useEffect(() => {
        GlobalState.setPageTitle('Banner management');
        GlobalState.setLayoutPageTitle('Banner management');
    }, []);

    const {id} = useParams();

    const [state, setState] = useState({banner: null, loading: true});
    const navigate = useNavigate();

    useEffect(() => {
        BannerApi.bannerGet({id})
            .then(response => {
                setState({
                    banner: response.data,
                    loading: false,
                });
            })
            .catch(error => {
                ErrorHandling.GlobalStateError(error);
                navigate('/banners', {state: {keepMessages: true}});
            });
    }, [id, navigate]);

    let bannerData = state.banner ? {
        id: state.banner.id,
        bannerZone: state.banner.bannerZone,
        imageUrl: state.banner.imageUrl,
        url: state.banner.url,
        target: state.banner.target,
        status: state.banner.status,
    } : {};

    const updateBanner = (data) => {
        setState(state => ({...state, loading: true}));

        BannerApi.bannerUpdate({id, bannerBannerUpdate: data})
            .then(() => {
                GlobalState.setSuccess('Successful operation!', 'Banner updated.');
                navigate(`/banners/zone/${uriToId(state.banner.bannerZone)}`, {state: {keepMessages: true}});
            })
            .catch(error => {
                ErrorHandling.GlobalStateError(error);
                setState(state => ({...state, loading: true}));
            })
    }

    return <PageCard
        title={`Editing banner`}
        loading={state.loading}
        actions={(
            state.loading
                ? ''
                : <BackButton to={`/banners/zone/${uriToId(state.banner.bannerZone)}`} text="Back to banners list" />
        )}
    >
        {state.banner ? <BannerForm data={bannerData} onSubmit={updateBanner} /> : ''}
    </PageCard>
};

export default WithMainLayoutPage(BannerEditPage);
