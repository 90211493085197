import qs from 'qs';

export default class Videos
{
    constructor(htvApi) {
        this.htvApi = htvApi;
    }

    Get(id) {
        return this.htvApi.Call(this.htvApi.url + '/videos/' + id);
    }

    List(page, perPage, orderByColumn, orderDescending, search = null) {
        let queryParameters = {
            'page': parseInt(page),
            'per-page': parseInt(perPage),
            'order-by': orderByColumn,
            'order-dir': orderDescending ? 'desc' : 'asc',
        };

        if (search) {
            queryParameters['search'] = search;
        }

        return this
            .htvApi
            .Call(this.htvApi.url + '/videos?' + qs.stringify(queryParameters, {encodeValuesOnly: true}));
    }

    Create(videoData) {
        const requestData = {
            slug: videoData.slug,
            source: videoData.source,
            sourceIdentifier: videoData.sourceIdentifier,
            status: videoData.status,
            titleTranslations: [],
            descriptionTranslations: [],
            categories: videoData.categories.map(id => '/video-categories/' + id),
        };

        for (const [locale, title] of Object.entries(videoData.titleTranslations)) {
            requestData.titleTranslations.push({locale: locale, value: title});
        }

        for (const [locale, description] of Object.entries(videoData.descriptionTranslations)) {
            requestData.descriptionTranslations.push({locale: locale, value: description});
        }

        return this.htvApi.Call(
            this.htvApi.url + '/videos',
            {
                method: 'POST',
                body: JSON.stringify(requestData),
            }
        );
    }

    Edit(id, videoData) {
        const requestData = {
            slug: videoData.slug,
            source: videoData.source,
            sourceIdentifier: videoData.sourceIdentifier,
            status: videoData.status,
            categories: videoData.categories.map(id => '/video-categories/' + id),
            titleTranslations: [],
            descriptionTranslations: [],
        };

        for (const [locale, title] of Object.entries(videoData.titleTranslations)) {
            requestData.titleTranslations.push({locale: locale, value: title});
        }

        for (const [locale, description] of Object.entries(videoData.descriptionTranslations)) {
            requestData.descriptionTranslations.push({locale: locale, value: description});
        }

        return this.htvApi.Call(
            this.htvApi.url + '/videos/' + id,
            {
                method: 'PATCH',
                body: JSON.stringify(requestData),
                contentType: 'application/merge-patch+json'
            }
        );
    }

    Delete(id) {
        return this.htvApi.Call(this.htvApi.url + '/videos/' + id, {method: 'DELETE'});
    }

    ResolveExternalVideo(service, identifier) {
        return this
            .htvApi
            .Call(this.htvApi.url + '/external-video-information/' + service + '/' + identifier);
    }

    ThumbnailUrl(id, width, height) {
        return this.htvApi.url + '/videos/' + id + '/thumbnail/' + width + '/' + height;
    }

    UpdateThumbnails(id) {
        return this.htvApi.Call(
            this.htvApi.url + '/videos/' + id + '/update-thumbnails',
            {
                method: 'POST',
                contentType: 'text/plain'
            }
        );
    }
}
