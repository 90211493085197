import Videos from "./Videos";
import Users from "./Users";
import VideoThumbnailResolutions from "./VideoThumbnailResolutions";
import HtvApiError from "./Error";
import VideoCategories from "./VideoCategories";
import NewsImageSizes from "./NewsImageSizes";
import NewsCategories from "./NewsCategories";
import Preferences from "./Preferences";
import {autorun} from "mobx";
import {AuthenticationState} from "../state/AuthenticationState";
import {logout} from "helpers/authentication";

function isJson(response) {
    let contentType = response?.headers?.get('Content-Type');

    if (typeof contentType !== 'string') {
        return false;
    }

    return contentType.indexOf('application/json') !== -1
        || contentType.indexOf('application/ld+json') !== -1;
}

function isBlob(response) {
    let contentType = response?.headers?.get('Content-Type');

    if (typeof contentType !== 'string') {
        return false;
    }

    return contentType.indexOf('image/') === 0
}

class HtvApi
{
    constructor(url) {
        this.url = url;
        this.token = '';
        this.Videos = new Videos(this);
        this.Users = new Users(this);
        this.VideoThumbnailResolutions = new VideoThumbnailResolutions(this);
        this.VideoCategories = new VideoCategories(this);
        this.NewsImageSizes = new NewsImageSizes(this);
        this.NewsCategories = new NewsCategories(this);
        this.Preferences = new Preferences(this);
    }

    setAuthenticationToken(token) {
        this.token = token;
    }

    Call(endpoint, options = {}) {
        let fetchOptions = {
            method: options.method || 'GET',
            headers: {
                'Content-Type': options.contentType || 'application/json',
                'Accept': 'application/ld+json, application/json, */*',
            },
            body: options.body || null,
            mode: 'cors',
        };

        if (this.token && this.token.length) {
            fetchOptions.headers['Authorization'] = 'Bearer ' + this.token;
        }

        console.log('HTV API Call: ' + endpoint);

        return fetch(endpoint, fetchOptions)
            .then(response => {
                if (isJson(response)) {
                    if (response.ok) {
                        return response
                            .json()
                            .then(json => {
                                return json;
                            })
                            .catch(error => {
                                console.error(
                                    'HTV API Exception! Expecting JSON response, but response could not be decoded!',
                                    error
                                );
                            });
                    }

                    if (response.status === 401) {
                        logout();

                        return response.json().then(json => {
                            throw new HtvApiError(
                                response.status,
                                json.message || '',
                            );
                        });
                    }

                    return response.json().then(json => {
                        console.error(json);
                        throw new HtvApiError(
                            response.status,
                            json['hydra:title'] || json['title'] || '',
                            json['hydra:description'] || json['detail'] || '',
                        );
                    });
                }

                if (response.status === 401) {
                    logout();

                    return response.text().then(text => {
                        throw new Error(text);
                    });
                }

                if (isBlob(response)) {
                    return response
                        .blob()
                        .catch(error => {
                            console.error(error);
                        })
                }

                return response
                    .text()
                    .catch(error => {
                        console.error(error);
                    })
            });
    }

    Login(email, password, code) {
        let request = {email, password, code};

        return this.Call(
            this.url + '/login',
            {
                method: 'POST',
                body: JSON.stringify(request)
            }
        );
    }
}

const api = new HtvApi(process.env.REACT_APP_HTV_API_URL);

autorun(() => {
    api.setAuthenticationToken(AuthenticationState.accessToken);
})

export {api as HtvApi};
export default api;
