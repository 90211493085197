import {NavLink, useResolvedPath, useMatch} from "react-router-dom";
import {useState} from "react";
import {Collapse} from "@mui/material";

const NavigationItem = ({
    target = null,
    icon,
    text,
    subNavigation = [],
    exactOnly = false,
    activePath = null,
}) => {
    let button;

    let subNavigationItems = [];
    let resolvedPath = useResolvedPath(activePath === null ? target : activePath);

    let isActive = useMatch({ path: resolvedPath.pathname, end: exactOnly });

    const [state, setState] = useState({
        isOpen: !!isActive,
        inTransition: false,
    })

    subNavigation.forEach((subNavigationItemParams, index) => {
        subNavigationItems.push(
            <NavigationItem
                key={index}
                target={subNavigationItemParams.target}
                text={subNavigationItemParams.text}
                icon={subNavigationItemParams.icon}
                exactOnly={subNavigationItemParams.exactOnly || false}
            />
        );
    });

    if (target === null) {
        button = <>
            <button
                className={'nav-link' + (isActive ? ' active' : '')}
                onClick={() => setState(state => ({isOpen: !state.isOpen, inTransition: true}))}
            >
                <i className={`nav-icon ${icon}`} />
                <p>
                    {text}
                    <i className="fas fa-angle-left right" />
                </p>
            </button>
            {subNavigationItems.length > 0
                ? <Collapse
                    in={state.isOpen}
                    addEndListener={(node, done) => {
                        const doneCallback = () => {
                            done();
                            setState(state => ({isOpen: state.isOpen, inTransition: false}))
                        };

                        node.addEventListener('transitionend', doneCallback, false);

                        setTimeout(() => {
                            node.removeEventListener('transitionend', doneCallback);
                        }, 2000);
                    }}
                >
                    <ul className="nav nav-treeview">
                        {subNavigationItems}
                    </ul>
                </Collapse>
                : ''
            }
        </>;
    } else {
        button = <NavLink
            to={target}
            className={() => 'nav-link' + (isActive ? ' active' : '')}
            end={exactOnly}
        >
            <i className={`nav-icon ${icon}`} />
            <p>{text}</p>
        </NavLink>
    }

    return <li className={'nav-item' + (state.isOpen || state.inTransition ? ' menu-open': '')}>{button}</li>
}

export default NavigationItem;
