import {useCallback, useEffect, useMemo, useState} from "react";
import DataTablePagination from "./DataTablePagination";
import Loader from "../Loader";
import "./DataTable.css";
import DataTablePerPage from "./DataTablePerPage";
import useQuery from "../../../../helpers/use-query";
import {useNavigate, useLocation} from "react-router-dom";
import DataTableInner from "./DataTableInner";
import qs from "qs";

const prepareTableData = (data, columns) => {
    let tableData = [];

    data.forEach(row => {
        let rowData = {};

        columns.forEach(column => {
            let columnName = typeof column.dataField !== 'undefined' ? column.dataField : column.field;
            let rawData = columnName ? row[columnName] : row;
            rowData[column.field] = column.render ? column.render(rawData) : rawData;
        });

        tableData.push(rowData);
    });

    return tableData;
};

const defaultPage = 1;
const defaultPerPage = 10;
const defaultSearch = [];

const DataTable = ({columns, fetchData, defaultSort}) => {
    console.log('--- RENDERING: DataTable ---');

    const mappedColumns = useMemo(() => columns.map(column => ({
        Header: column.label,
        accessor: column.field,
        defaultCanSort: column.sortable || false,
        disableSortBy: !(column.sortable || false),
        defaultCanFilter: column.searchable || false,
        disableFilters: !(column.searchable || false),
    })), [columns]);

    const urlParams = useQuery();
    const location = useLocation();

    // Page index
    const [page, setPage] = useState(defaultPage);
    const urlPage = urlParams['page'] ? parseInt(urlParams['page']) : defaultPage;

    if (urlPage > 0 && urlPage < 9999 && urlPage !== page) {
        setPage(urlPage);
    }
    // END Page index

    // Number of results per page
    const [perPage, setPerPage] = useState(defaultPerPage);
    const urlPerPage = urlParams['per-page'] ? parseInt(urlParams['per-page']) : defaultPerPage;

    if (urlPerPage > 0 && urlPerPage < 101 && urlPerPage !== perPage) {
        setPerPage(urlPerPage);
    }
    // END Number of results per page

    // Order by column
    const [orderBy, setOrderBy] = useState(defaultSort.column);
    const urlOrderBy = urlParams['order-by'] || defaultSort.column;

    if (urlOrderBy !== orderBy) {
        for (const columnData of columns) {
            if (columnData.field === urlOrderBy) {
                setOrderBy(urlOrderBy);
                break;
            }
        }
    }
    // END Order by column

    // Order in descending order or not
    const [orderDescending, setOrderDescending] = useState(defaultSort.desc);
    const urlOrderDescending = urlParams['order-dir'] === 'desc';

    if (Object.prototype.hasOwnProperty.call(urlParams, 'order-dir')) {
        if (urlOrderDescending !== orderDescending) {
            setOrderDescending(urlOrderDescending);
        }
    } else if (orderDescending !== defaultSort.desc) {
        setOrderDescending(defaultSort.desc);
    }
    // END Order in descending order or not

    // Search filter
    const [searchFilter, setSearchFilter] = useState(defaultSearch);
    const urlSearchFilter = urlParams['search'] || defaultSearch;

    if (JSON.stringify(urlSearchFilter) !== JSON.stringify(searchFilter)) {
        setSearchFilter(urlSearchFilter);
    }
    // END Search filter

    const navigate = useNavigate();

    const goTo = useCallback((page, perPage, orderBy, orderDescending, search) => {
        setTableState(tableState => Object.assign({}, tableState, {loading: true}));

        let newUrlParams = {};

        if (page !== defaultPage) {
            newUrlParams['page'] = page;
        }

        if (perPage !== defaultPerPage) {
            newUrlParams['per-page'] = perPage;
        }

        if (orderBy !== defaultSort.column || orderDescending !== defaultSort.desc) {
            newUrlParams['order-by'] = orderBy;
            newUrlParams['order-dir'] = orderDescending ? 'desc' : 'asc';
        }

        if (Object.keys(search).length) {
            newUrlParams['search'] = search;
        }

        let queryString = qs.stringify(newUrlParams, {encodeValuesOnly: true});

        if (queryString.length) {
            queryString = '?' + queryString;
        }

        navigate(location.pathname + queryString);
    }, [navigate, defaultSort, location.pathname]);

    const [tableState, setTableState] = useState({
        rows: [],
        totalPages: 0,
        loading: true
    });

    useEffect(() => {
        fetchData(page, perPage, orderBy, orderDescending, searchFilter).then(response => {
            setTableState({
                rows: prepareTableData(response['hydra:member'], columns),
                totalPages: Math.ceil(response['hydra:totalItems'] / perPage),
                loading: false,
            });
        }).catch(() => {
            setTableState(tableState => Object.assign({}, tableState, {loading: false}));
        });
    }, [page, perPage, orderBy, orderDescending, searchFilter, columns, fetchData]);

    const onSort = useCallback(
        (sort) => goTo(page, perPage, sort.id, sort.desc, searchFilter),
        [page, perPage, searchFilter, goTo]
    );
    const tableSorting = useMemo(() => ({id: orderBy, desc: orderDescending}), [orderBy, orderDescending]);

    const onSearch = useCallback(filters => {
        goTo(page, perPage, orderBy, orderDescending, filters)
    }, [goTo, page, perPage, orderBy, orderDescending]);

    let searchFields = useMemo(() => {
        let searchFilters = [];

        for (const [key, value] of Object.entries(searchFilter)) {
            searchFilters.push({id: key, value: value});
        }

        return searchFilters;
    }, [searchFilter]);

    return (
        <div className="overlay-wrapper">
            <div className="row">
                <DataTableInner
                    columns={mappedColumns}
                    data={tableState.rows}
                    sorting={tableSorting}
                    onSort={onSort}
                    search={searchFields}
                    onSearch={onSearch}
                />
            </div>
            <div className="row">
                <div className="col-sm-12 col-md-5">
                    <DataTablePerPage
                        perPage={perPage}
                        setPerPage={(perPage) => goTo(page, perPage, orderBy, orderDescending, searchFilter)}
                    />
                </div>
                <div className="col-sm-12 col-md-7">
                    <DataTablePagination
                        currentPage={page}
                        totalPages={tableState.totalPages}
                        perPage={perPage}
                        setPage={(page) => goTo(page, perPage, orderBy, orderDescending, searchFilter)}
                    />
                </div>
            </div>
            {tableState.loading ? <Loader /> : ''}
        </div>
    );
};

export default DataTable;
