import {Link} from "react-router-dom";
import {useEffect, useState} from "react";
import HtvApi from "../../htv-api/HtvApi";
import {GlobalState} from "../../state/GlobalState";
import {logout} from "../../helpers/authentication";

export default function LoggedUser() {
    const [name, setName] = useState('');

    useEffect(() => {
        HtvApi.Users.Profile().then(user => {
            if (
                !Object.prototype.hasOwnProperty.call(user, 'roles')
                || !Array.isArray(user.roles)
                || !user.roles.includes('ROLE_ADMIN')
            ) {
                GlobalState.setError('Not enough permissions to view this page');
                logout();
                return;
            }

            setName(user['firstName'] + ' ' + user['lastName']);
        });
    }, []);

    return (
        <div className="user-panel mt-3 pb-3 mb-3 d-flex">
            <div className="image">
                <img src="/images/avatar.png" className="img-circle elevation-2" alt="User Avatar"/>
            </div>
            <div className="info">
                <Link to="/" className="d-block">{name}</Link>
            </div>
        </div>
    )
}
