import WithMainLayoutPage from "../WithMainLayoutPage";
import PageCard from "../../layouts/main/components/PageCard";
import BannersList from "./BannersList";
import {useCallback, useEffect, useRef, useState} from "react";
import {GlobalState} from "../../state/GlobalState";
import {useNavigate, useParams} from "react-router-dom";
import {ErrorHandling} from "../../helpers/error-handling";
import BackButton from "../../layouts/main/components/Button/BackButton";
import AddButton from "../../layouts/main/components/Button/AddButton";
import SaveButton from "../../layouts/main/components/Button/SaveButton";
import {BannerZoneApi} from "../../HtvApi/Api";

const BannersListingPage = () => {
    useEffect(() => {
        GlobalState.setPageTitle('Banner management');
        GlobalState.setLayoutPageTitle('Banner management');
    }, []);

    const {id} = useParams();

    const [state, setState] = useState({bannerZone: null, loading: true});

    const bannerPositions = useRef([]);
    const navigate = useNavigate();

    const onBannerPositionsChange = useCallback(bannerURIs => {
        bannerPositions.current = bannerURIs;
    }, []);

    const saveBannersOrder = () => {
        setState(state => ({...state, loading: true}));

        BannerZoneApi.postBannerZoneBannerOrdering({id, requestBody: bannerPositions.current})
            .then(() => {
                GlobalState.setSuccess('Success', 'Banner zone banners positions saved successfully!');
            })
            .catch(ErrorHandling.GlobalStateError)
            .then(() => setState(state => ({...state, loading: false})));
    };

    useEffect(() => {
        BannerZoneApi.bannerZoneGet({id})
            .then(response => {
                setState(state => ({
                    ...state,
                    bannerZone: {
                        id: response.data.id,
                        key: response.data.key,
                        description: response.data.description,
                        width: response.data.width,
                        height: response.data.height,
                    },
                    loading: false,
                }));
            })
            .catch(error => {
                ErrorHandling.GlobalStateError(error);
                navigate('/banners', {state: {keepMessages: true}});
            });
    }, [id, navigate]);

    return (
        <PageCard
            loading={state.loading}
            title={state.bannerZone
                ? `Banners in zone: ${state.bannerZone.key} | Image size: ${state.bannerZone.width}x${state.bannerZone.height}`
                : ''
            }
            actions={<>
                <BackButton to="/banners" text="Back to banner zone list" />
                <SaveButton onClick={() => saveBannersOrder()} text="Save banners positions" />
                <AddButton to={`/banners/zone/${id}/create-banner`} text="Create new banner" />
            </>}
        >
            {state.bannerZone
                ? <BannersList zoneData={state.bannerZone} onBannerPositionsChange={onBannerPositionsChange} />
                : ''
            }
        </PageCard>
    )
}

export default WithMainLayoutPage(BannersListingPage);
