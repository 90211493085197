import {useCallback, useState} from "react";
import uniqueId from "../../../../helpers/unique-id";

const FormChoice = ({
    name,
    label,
    options,
    initialValue,
    onChange,
    testId = null,
}) => {
    const [value, setValue] = useState(initialValue);
    const onChangeFn = useCallback(event => {
        setValue(event.target.value);
        onChange(event.target.value, name);
    }, [onChange, name]);

    let radios = [];

    for (const [optionName, optionValue] of Object.entries(options)) {
        const id = uniqueId();
        const radioLabel = typeof optionValue === 'string' ? optionValue : optionValue.label;
        const icon = optionValue.icon ? <i className={optionValue.icon} /> : '';
        const color = optionValue.color || '';
        let inputColorClass = '';
        let labelColorClass = '';

        switch (color) {
            case 'red':
                inputColorClass = 'custom-control-input-danger';
                labelColorClass = 'text-danger';
                break;

            case 'blue':
                inputColorClass = 'custom-control-input-blue';
                labelColorClass = 'text-primary';
                break;

            case 'green':
                inputColorClass = 'custom-control-input-green';
                labelColorClass = 'text-success';
                break;

            case 'gray':
                inputColorClass = 'custom-control-input-gray';
                labelColorClass = 'text-muted';
                break;

            default:
                break;
        }

        radios.push(
            <div className="custom-control custom-radio" key={optionName}>
                <input
                    className={'custom-control-input ' + inputColorClass}
                    type="radio"
                    name={name}
                    value={optionName}
                    id={id}
                    checked={value === optionName}
                    onChange={onChangeFn}
                    data-testid={testId ? testId + '-' + optionName : null}
                />
                <label
                    className={'custom-control-label ' + labelColorClass}
                    htmlFor={id}
                >
                    {icon} {radioLabel}
                </label>
            </div>
        )
    }

    return (
        <div className="form-group row">
            <label className="col-sm-2 col-form-label">{label}:</label>
            <div className="col-sm-10">
                <div className="form-group">
                    {radios}
                </div>
            </div>
        </div>
    )
};

export default FormChoice;
