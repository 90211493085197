import WithMainLayoutPage from "../WithMainLayoutPage";
import {useCallback, useEffect, useState} from "react";
import {GlobalState} from "../../state/GlobalState";
import PageCard from "../../layouts/main/components/PageCard";
import {Link} from "react-router-dom";
import {ErrorHandling} from "../../helpers/error-handling";
import DataTable from "../../layouts/main/components/DataTable/DataTable";
import dayjs from "dayjs";
import NewsInformationDialogButton from "./NewsInformationDialogButton";
import NewsDeleteDialogButton from "./NewsDeleteDialogButton";
import {NewsApi} from "../../HtvApi/Api";

const NewsPage = () => {
    useEffect(() => {
        GlobalState.setPageTitle('News management');
        GlobalState.setLayoutPageTitle('News management');
    }, []);

    const [,setDataVersion] = useState(0);

    const fetchTableData = useCallback((page, perPage, orderByColumn, orderDescending, search) => {
        let parameters = {
            page,
            perPage,
            orderBy: orderByColumn,
            orderDir: orderDescending ? 'desc': 'asc'
        };

        for (const [key, value] of Object.entries(search)) {
            switch (key) {
                case 'id': parameters['searchId'] = value; break;
                case 'title': parameters['searchTitle'] = value; break;
                case 'subtitle': parameters['searchSubtitle'] = value; break;
                case 'slug': parameters['searchSlug'] = value; break;
                case 'status': parameters['searchStatus'] = value; break;
                default: console.error('Invalid search key: ' + key);
            }
        }

        return NewsApi
            .newsGetCollection(parameters)
            .then(response => response.data)
            .catch((error) => {
                ErrorHandling.GlobalStateError(error)
                throw error;
            });
    }, []);

    const dataTable = (
        <DataTable
            fetchData={fetchTableData}
            defaultSort={{column: 'createdAt', desc: true}}
            columns={[
                {
                    label: 'Title',
                    field: 'title',
                    sortable: true,
                    searchable: true,
                    dataField: null,
                    render: (row) => (<><div>{row.title}</div><div className="text-muted">{row.subTitle}</div></>),
                },
                {
                    label: 'Link',
                    field: 'slug',
                    sortable: true,
                    searchable: true,
                },
                {
                    label: 'Status',
                    field: 'status',
                    sortable: true,
                    searchable: true,
                },
                {
                    label: 'Created at',
                    field: 'createdAt',
                    render: (date) => dayjs(date).format('D MMM YYYY H:mm:ss'),
                    sortable: true,
                },
                {
                    label: 'Read count',
                    field: 'readCount',
                    sortable: true,
                },
                {
                    label: 'Options',
                    field: 'options',
                    dataField: null,
                    render: (row) => {
                        return (
                            <>
                                <NewsInformationDialogButton newsId={row.id} />
                                &nbsp;
                                <Link
                                    className="btn btn-warning btn-sm"
                                    to={'/news/edit/' + row.id}
                                    data-testid={'edit-news-' + row.id}
                                >
                                    <i className="fas fa-pencil-alt" /> Edit
                                </Link>
                                &nbsp;
                                <NewsDeleteDialogButton
                                    id={row.id}
                                    onConfirm={() => setDataVersion(version => version + 1)}
                                />
                            </>
                        );
                    },
                }
            ]}
        />
    );

    return (
        <PageCard
            title='News list'
            actions={(
                <Link to="/news/create" className="btn btn-sm btn-success">
                    <i className="fas fa-plus" />&nbsp;&nbsp;&nbsp;Add new
                </Link>
            )}
        >
            {dataTable}
        </PageCard>
    );
};

export default WithMainLayoutPage(NewsPage);
