import WithMainLayoutPage from "pages/WithMainLayoutPage";
import {GlobalState} from "state/GlobalState";
import PageCard from "layouts/main/components/PageCard";
import {useEffect} from "react";
import ContactMessagesList from "pages/ContactMessages/ContactMessagesList";

const ContactMessagesPage = () => {
    useEffect(() => {
        GlobalState.setPageTitle('Contact messages');
        GlobalState.setLayoutPageTitle('Contact messages');
    }, []);

    return <PageCard
        title={'Submitted contact messages'}
    >
        <ContactMessagesList />
    </PageCard>
}

export default WithMainLayoutPage(ContactMessagesPage);
