import {makeAutoObservable} from "mobx";
import {BrowserStorage} from "../BrowserStorage";
import NonReactiveState from "./NonReactiveState";

class GlobalStateClass {
    hasError = false;
    errorTitle = '';
    errorDescription = '';

    hasSuccess = false;
    successTitle = '';
    successDescription = '';

    pageTitle = 'HTV Admin';
    layoutPageTitle = '';

    constructor(savedState = null) {
        if (savedState) {
            for (const [key, value] of Object.entries(savedState)) {
                this[key] = value;
            }
        }

        makeAutoObservable(this);
    }

    setError(title, description) {
        this.clearMessages();
        console.log('Adding error message');
        NonReactiveState.messageDisplayed = false;
        this.hasError = true;
        this.errorTitle = title;
        this.errorDescription = description;
        window.scrollTo({top: 0, behavior: 'smooth'});
    }

    setSuccess(title, description) {
        this.clearMessages();
        console.log('Adding success message');
        NonReactiveState.messageDisplayed = false;
        this.hasSuccess = true;
        this.successTitle = title;
        this.successDescription = description;
        window.scrollTo({top: 0, behavior: 'smooth'});
    }

    clearMessages() {
        console.log('Clearing messages...');
        this.hasError = false;
        this.hasSuccess = false;
    }

    setPageTitle(title) {
        this.pageTitle = title + ' | HTV Admin';
    }

    setLayoutPageTitle(title) {
        this.layoutPageTitle = title;
    }
}

const savedState = BrowserStorage.get('app-state');
export const GlobalState = new GlobalStateClass(savedState);
