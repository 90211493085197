import LoginForm from "./LoginForm";
import BrowserTitle from "../common/BrowserTitle";

export default function LoginLayout() {
    document.body.className = 'hold-transition login-page';

    return (
        <>
            <BrowserTitle />
            <div className="login-box">
                <div className="card card-outline card-primary">
                    <div className="card-header text-center">
                        <span className="h1"><b>HTV</b> Admin</span>
                    </div>
                    <div className="card-body">
                        <LoginForm />
                    </div>
                </div>
            </div>
        </>
    );
}
