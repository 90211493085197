import {useLocation} from "react-router-dom";
import qs from "qs";

const useQuery = () => {
    let url = useLocation().search;

    if (url.length > 0 && url[0] === '?') {
        url = url.substring(1);
    }

    return qs.parse(url);
}

export default useQuery;
