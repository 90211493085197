import {useState} from "react";
import {GlobalState} from "../../state/GlobalState";
import {ErrorHandling} from "../../helpers/error-handling";
import ConfirmDeletionDialog from "../../layouts/main/components/ConfirmDeletionDialog";
import {BannerZoneApi} from "../../HtvApi/Api";

const BannerZoneDeleteDialogButton = ({id, onConfirm}) => {
    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState(null);

    const closeDialog = () => {
        setIsLoading(false);
        setData(null);
    };

    const confirmDelete = () => {
        setIsLoading(true);

        BannerZoneApi.bannerZoneDelete({id})
            .then(() => {
                GlobalState.setSuccess('Successful operation!', 'Banner zone deleted successfully!');
                closeDialog();
                onConfirm && onConfirm();
            })
            .catch(error => {
                ErrorHandling.GlobalStateError(error);
                closeDialog();
            });
    };

    const showConfirmDialog = (e) => {
        e.preventDefault();
        setIsLoading(true);

        BannerZoneApi.bannerZoneGet({id}).then(response => {
            setData({
                key: response.data.key,
            });
            setIsLoading(false);
        }).catch(error => {
            ErrorHandling.GlobalStateError(error);
            closeDialog();
        });
    };

    const button =
        <button className="btn btn-sm btn-danger" onClick={e => showConfirmDialog(e)}>
            <i className="far fa-trash-alt" /> Delete
        </button>

    if (data || isLoading) {
        return (
            <>
                {button}
                <ConfirmDeletionDialog
                    isLoading={isLoading}
                    title={data ? `Confirm deletion of banner zone: ${data.key}` : ''}
                    description={''}
                    onCancel={() => closeDialog()}
                    onConfirm={() => confirmDelete()}
                />
            </>
        );
    }

    return button;
};

export default BannerZoneDeleteDialogButton;
