import './MainLayout.css';
import Alerts from "./Alerts";
import BrowserTitle from "../common/BrowserTitle";
import PageHeader from "./components/PageHeader";
import RequireAuthorization from "./RequireAuthorization";
import {Outlet} from "react-router-dom";
import {logout} from "../../helpers/authentication";
import MUITheme from "./MUITheme";
import Navigation from "./navigation/Navigation";

const MainLayout = () => {
    document.body.className = 'sidebar-mini';
    console.log('Here in main layout');

    return (
        <RequireAuthorization>
            <MUITheme>
                <BrowserTitle />
                <div className="wrapper">

                    <nav className="main-header navbar navbar-expand navbar-white navbar-light">
                        <ul className="navbar-nav">
                            <li className="nav-item">
                                <button className="nav-link" data-widget="pushmenu">
                                    <i className="fas fa-bars"/>
                                </button>
                            </li>
                        </ul>

                        <ul className="navbar-nav ml-auto">
                            <li className="nav-item">
                                <button className="nav-link" title="Logout" onClick={() => logout()}>
                                    <i className="fas fa-sign-out-alt"/>
                                </button>
                            </li>
                        </ul>
                    </nav>

                    <Navigation />

                    <div className="content-wrapper">
                        <PageHeader />

                        <div className="content">
                            <div className="container-fluid">
                                <Alerts />
                                <Outlet />
                            </div>
                        </div>
                    </div>

                    <footer className="main-footer">
                        <strong>Copyright &copy; 2022 <a href="https://htv.bg">HTV.bg</a>.</strong> All rights reserved.
                    </footer>
                </div>
            </MUITheme>
        </RequireAuthorization>
    );
};

export default MainLayout;
