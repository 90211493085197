import WithMainLayoutPage from "../../WithMainLayoutPage";
import PageCard from "../../../layouts/main/components/PageCard";
import {Link, useNavigate, useParams} from "react-router-dom";
import BannerZoneForm from "../BannerZoneForm";
import {ErrorHandling} from "../../../helpers/error-handling";
import {useEffect, useState} from "react";
import {GlobalState} from "../../../state/GlobalState";
import {BannerZoneApi} from "../../../HtvApi/Api";

const BannerZoneUpdatePage = () => {
    useEffect(() => {
        GlobalState.setPageTitle('Banner management');
        GlobalState.setLayoutPageTitle('Banner management');
    }, []);

    const {id} = useParams();
    const [loading, setLoading] = useState(true);
    const [zoneData, setZoneData] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        BannerZoneApi.bannerZoneGet({id})
            .then(response => {
                setZoneData({
                    id: response.data.id,
                    key: response.data.key,
                    description: response.data.description,
                    width: response.data.width,
                    height: response.data.height,
                })
                setLoading(false);
            })
            .catch(error => {
                ErrorHandling.GlobalStateError(error);
                navigate('/banners', {state: {keepMessages: true}});
            });
    }, [id, navigate]);

    const updateBannerZone = data => {
        setLoading(true);

        BannerZoneApi.bannerZoneUpdate({id, bannerZoneBannerZoneUpdate: data})
            .then(() => {
                GlobalState.setSuccess('Successful operation!', 'Banner zone updated.');
                navigate('/banners', {state: {keepMessages: true}});
            })
            .catch(error => {
                ErrorHandling.GlobalStateError(error);
                setLoading(false);
            })
    }

    return <PageCard
        title="Update banner zone"
        loading={loading}
        actions={(
            <Link to="/banners" className="btn btn-sm btn-danger">
                <i className="fas fa-arrow-left" />&nbsp;&nbsp;&nbsp;Back to banner zone list
            </Link>
        )}
    >
        {zoneData ? <BannerZoneForm data={zoneData} onSubmit={updateBannerZone} /> : ''}
    </PageCard>
};

export default WithMainLayoutPage(BannerZoneUpdatePage);
