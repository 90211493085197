const ActionButton = ({actions}) => {
    return <div className="btn-group btn-group-sm">
        <button type="button" className="btn btn-info">Action</button>
        <button type="button" className="btn btn-info dropdown-toggle dropdown-icon" data-toggle="dropdown">
            <span className="sr-only">Toggle Dropdown</span>
        </button>
        <div className="dropdown-menu" role="menu">
            {actions.map((action, index) => {
                return <button
                    key={index}
                    className="dropdown-item"
                    onClick={action.onClick}
                    style={{cursor: 'pointer'}}
                >
                    {action.name}
                </button>
            })}
        </div>
    </div>
};

export default ActionButton;
