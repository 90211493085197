import ConfirmDeletionDialog from "layouts/main/components/ConfirmDeletionDialog";
import {useState} from "react";

const DeleteObjectStorageDialogButton = ({objectStorage, onConfirm}) => {
    const [showDialog, setShowDialog] = useState(false);

    const closeDialog = () => {
        setShowDialog(false);
    };

    const confirmDelete = () => {
        closeDialog();
        onConfirm(objectStorage.id);
    };

    const showConfirmDialog = (e) => {
        e.preventDefault();
        setShowDialog(true);
    };

    const button =
        <button className="btn btn-sm btn-danger" onClick={e => showConfirmDialog(e)}>
            <i className="far fa-trash-alt" /> Delete
        </button>

    if (showDialog) {
        let title = '';
        let description = '';

        if (objectStorage.type === 'directory') {
            title = 'Confirm the deletion of the whole directory and it\'s contents';
            description = 'The directory ' + objectStorage.name + ' and all of it\'s contents will be permanently deleted';
        } else {
            title = 'Confirm single file deletion';
            description = 'The file ' + objectStorage.name + ' will be permanently deleted';
        }

        return <>
            {button}
            <ConfirmDeletionDialog
                isLoading={false}
                title={title}
                description={description}
                onCancel={() => closeDialog()}
                onConfirm={() => confirmDelete()}
            />
        </>
    }

    return button;
}

export default DeleteObjectStorageDialogButton;
