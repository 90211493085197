import WithMainLayoutPage from "../../WithMainLayoutPage";
import PageCard from "../../../layouts/main/components/PageCard";
import Locales from "../../../Locales";
import {Tab, Tabs} from "react-bootstrap";
import FormTextField from "../../../layouts/main/components/Form/FormTextField";
import {useCallback, useEffect, useMemo, useRef, useState} from "react";
import FormChoice from "../../../layouts/main/components/Form/FormChoice";
import FormSelect from "../../../layouts/main/components/Form/FormSelect";
import HtvApi from "../../../htv-api/HtvApi";
import {GlobalState} from "../../../state/GlobalState";
import {Link, useNavigate} from "react-router-dom";
import {ErrorHandling} from "../../../helpers/error-handling";

const mapCategories = (categories, parent = null) => {
    let mappedCategories = [];

    categories.forEach(category => {
        const categoryParent = category?.parent?.id || null;

        if (categoryParent !== parent) {
            return;
        }

        mappedCategories.push({value: category.id, label: category.title});

        const children = mapCategories(categories, category.id);

        children.forEach(category => {
            mappedCategories.push({value: category.value, label: '|\u00A0\u00A0\u00A0\u00A0' + category.label});
        })
    })

    return mappedCategories;
}

const VideoCategoriesAddPage = () => {
    const data = useRef({
        titleTranslations: {},
        slug: '',
        status: 'enabled',
    });

    const mainCategory = useMemo(() => ({value: null, label: 'Main Category'}), []);

    const [parentCategory, setParentCategory] = useState(mainCategory);
    const [allCategories, setAllCategories] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const navigate = useNavigate();

    useEffect(() => {
        HtvApi.VideoCategories.List().then(response => {
            setAllCategories([mainCategory, ...mapCategories(response['hydra:member'])]);
            setParentCategory(mainCategory)
        });
    }, [mainCategory]);

    const onSubmit = (event) => {
        event.preventDefault();
        setIsLoading(true);

        HtvApi.VideoCategories.Create({
            titleTranslations: data.current.titleTranslations,
            slug: data.current.slug,
            status: data.current.status,
            parentCategory: parentCategory.value,
        }).then((response) => {
            GlobalState.setSuccess('Successful operation!', 'Video category ' + response.title + ' created successfully!');
            navigate('/videos/categories', {state: {keepMessages: true}});
        }).catch(error => {
            setIsLoading(false);
            ErrorHandling.GlobalStateError(error);
        });
    }

    const i18nTabs = [];

    for (const [locale, localeTitle] of Object.entries(Locales)) {
        i18nTabs.push(
            <Tab eventKey={locale} title={localeTitle} key={locale}>
                <div className="mt-3">
                    <FormTextField
                        label={'Title (' + localeTitle + ')'}
                        // eslint-disable-next-line react-hooks/rules-of-hooks
                        onChange={useCallback(
                            value => data.current.titleTranslations[locale] = value, [locale]
                        )}
                    />
                </div>
            </Tab>
        )
    }

    return (
        <PageCard title='Add video category' loading={isLoading}>
            <form onSubmit={onSubmit}>
                <Tabs>
                    {i18nTabs}
                </Tabs>

                <FormTextField
                    label='Link'
                    onChange={useCallback(value => data.current.slug = value, [])}
                />

                <FormChoice
                    name="status"
                    label="Status"
                    options={{
                        enabled: {
                            label: 'Enabled',
                            color: 'green',
                            icon: 'fas fa-check-circle',
                        },
                        disabled: {
                            label: 'Disabled',
                            color: 'red',
                            icon: 'fas fa-times-circle',
                        },
                    }}
                    initialValue={data.current.status}
                    onChange={useCallback(value => data.current.status = value, [])}
                />
                <FormSelect
                    label='Parent category'
                    value={parentCategory}
                    onChange={category => setParentCategory(category)}
                    values={allCategories}
                />

                <div>
                    <button
                        type="submit"
                        className="btn btn-success"
                    >
                        Create video category
                    </button>
                    <Link
                        to="/videos/categories"
                        type="button"
                        className="btn btn-danger float-right"
                    >
                        Cancel (changes will be lost)
                    </Link>
                </div>
            </form>
        </PageCard>
    );
};

export default WithMainLayoutPage(VideoCategoriesAddPage);
