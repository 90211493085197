import $ from "jquery";
import uniqueId from "../../../../helpers/unique-id";
import {useEffect, useRef} from "react";
import 'summernote/dist/summernote-bs4';
import 'summernote/dist/summernote-bs4.css';

const FormEditor = ({
    label,
    initialValue = '',
    onChange,
    testId = null,
}) => {
    const id = useRef(uniqueId());
    const initValue = useRef(initialValue);

    useEffect(() => {
        let editorId = id.current;

        console.log('Initializing summernote editor...');

        $('#' + editorId).summernote({
            height: 200,
            minHeight: 200,
            focus: false,
            toolbar: [
                ['style', ['style', 'clear']],
                ['font', ['bold', 'underline', 'strikethrough', 'superscript', 'subscript']],
                ['size', ['fontsize', 'fontsizeunit']],
                ['color', ['color']],
                ['para', ['ul', 'ol', 'paragraph']],
                ['table', ['table']],
                ['insert', ['link', 'hr']],
            ],
            callbacks: {
                onChange: contents => onChange(contents),
            }
        });

        return () => {
            $('#' + editorId).summernote('destroy');
        };
    }, [onChange]);

    return (
        <div className="form-group row">
            <label className="col-sm-2 col-form-label" onClick={ () => $('#' + id.current).summernote('focus') }>
                {label}:
            </label>
            <div className="col-sm-10" data-testid={testId}>
                <div id={id.current} dangerouslySetInnerHTML={{__html: initValue.current}} />
            </div>
        </div>
    )
};

export default FormEditor;
