import {Button, Modal} from "react-bootstrap";
import FormTextField from "layouts/main/components/Form/FormTextField";
import {useRef} from "react";

const CreateDirectoryDialog = ({onCancel, onSubmit}) => {
    const directoryName = useRef('');

    return <>
        <Modal
            show={true}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            onHide={onCancel}
        >
            <Modal.Header closeButton>
                <Modal.Title>Create new directory</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <FormTextField
                    name="name"
                    label="Name"
                    initialValue={directoryName.current}
                    placeholder="Enter directory name"
                    onChange={value => directoryName.current = value}
                />
            </Modal.Body>

            <Modal.Footer>
                <Button variant="secondary" onClick={onCancel}>Close</Button>
                <Button variant="success" onClick={() => onSubmit(directoryName.current)}>Create</Button>
            </Modal.Footer>
        </Modal>
    </>
};

export default CreateDirectoryDialog;
