import Locales from "../../Locales";
import {Tab, Tabs} from "react-bootstrap";
import FormTextField from "../../layouts/main/components/Form/FormTextField";
import {useCallback, useRef} from "react";
import FormEditor from "../../layouts/main/components/Form/FormEditor";
import FormDateTimePicker from "../../layouts/main/components/Form/FormDateTimePicker";
import FormChoice from "../../layouts/main/components/Form/FormChoice";
import {Link} from "react-router-dom";
import NewsFormImages from "./NewsFormImages";
import {Map} from "immutable";
import NewsFormCategories from "./NewsFormCategories";
import FormSubmit from "../../layouts/main/components/Form/FormSubmit";
import {GetTranslation, SetTranslation} from "../../HtvApi/Api";

const NewsForm = ({data = {}, onSubmit}) => {
    const formData = useRef(
        Object.assign({
            titleTranslations: [],
            subTitleTranslations: [],
            contentTranslations: [],
            slug: '',
            source: '',
            status: 'public',
            vimeoVideo: '',
            displayDate: new Date(),
            images: Map(),
            categories: [],
        }, data || {})
    );
    const tabs = [];

    const submitFn = event => {
        event.preventDefault();
        onSubmit(formData.current);
    }

    for (const [locale, localeTitle] of Object.entries(Locales)) {
        tabs.push(
            <Tab eventKey={locale} title={localeTitle} key={locale}>
                <div className="mt-3">
                    <FormTextField
                        label={'Title (' + localeTitle + ')'}
                        initialValue={GetTranslation(formData.current.titleTranslations, locale)}
                        // eslint-disable-next-line react-hooks/rules-of-hooks
                        onChange={useCallback(
                            value => SetTranslation(formData.current.titleTranslations, locale, value),
                            [locale]
                        )}
                        testId={`input-title-${locale}`}
                    />

                    <FormTextField
                        label={'Subtitle (' + localeTitle + ')'}
                        initialValue={GetTranslation(formData.current.subTitleTranslations, locale)}
                        // eslint-disable-next-line react-hooks/rules-of-hooks
                        onChange={useCallback(
                            value => SetTranslation(formData.current.subTitleTranslations, locale, value),
                            [locale]
                        )}
                        testId={`input-subtitle-${locale}`}
                    />
                </div>

                <FormEditor
                    label={`Content (${localeTitle})`}
                    initialValue={GetTranslation(formData.current.contentTranslations, locale)}
                    // eslint-disable-next-line react-hooks/rules-of-hooks
                    onChange={useCallback(
                        value => SetTranslation(formData.current.contentTranslations, locale, value),
                        [locale]
                    )}
                    testId={`input-content-${locale}`}
                />
            </Tab>
        )
    }

    return (
        <form
            className="form-horizontal"
            onSubmit={submitFn}
        >
            <Tabs>
                {tabs}
            </Tabs>
            <hr />

            <FormTextField
                label={'Link'}
                initialValue={formData.current.slug ?? ''}
                onChange={useCallback(value => formData.current.slug = value, [])}
                testId={'input-slug'}
            />

            <FormTextField
                label={'Source'}
                initialValue={formData.current.source ?? ''}
                onChange={useCallback(value => formData.current.source = value, [])}
                testId={'input-source'}
            />

            <FormDateTimePicker
                label={'Display date'}
                initialValue={formData.current.displayDate}
                onChange={useCallback(value => formData.current.displayDate = value, [])}
                required={true}
            />

            <FormTextField
                label={'Vimeo video'}
                initialValue={formData.current.vimeoVideo ?? ''}
                onChange={useCallback(value => formData.current.vimeoVideo = value, [])}
                testId={'input-vimeo-video'}
            />

            <FormChoice
                name="status"
                label="Status"
                options={{
                    public: {
                        label: 'Public',
                        color: 'green',
                        icon: 'far fa-eye',
                    },
                    hidden: {
                        label: 'Hidden',
                        color: 'gray',
                        icon: 'fas fa-eye-slash',
                    },
                }}
                initialValue={formData.current.status}
                onChange={useCallback(value => formData.current.status = value, [])}
                testId={'input-status'}
            />

            <NewsFormCategories
                selected={formData.current.categories}
                onChange={useCallback(values => formData.current.categories = values, [])}
            />

            <hr />
            <hr />

            <NewsFormImages
                images={formData.current.images}
                onChange={useCallback(images => formData.current.images = images, [])}
            />

            <hr />

            <FormSubmit label={Object.keys(data).length === 0 ? 'Create news' : 'Save changes'} />

            <Link
                to="/news"
                type="button"
                className="btn btn-danger float-right"
            >
                Cancel (changes will be lost)
            </Link>
        </form>
    )
};

export default NewsForm;
