import {useEffect, useRef, useState} from "react";
import uniqueId from "../../../../helpers/unique-id";
import bsCustomFileInput from 'bs-custom-file-input'

const FormUploadFile = ({
    label,
    onChange,
    placeholder = '',
    errors = null,
    testId = null,
}) => {
    const [id] = useState(uniqueId());
    const fileInput = useRef();

    useEffect(() => {
        bsCustomFileInput.init();
    });

    return (
        <div className="form-group row">
            <label
                htmlFor={id}
                className="col-sm-2 col-form-label"
            >
                {label}:
            </label>
            <div className="col-sm-10">
                <div className="custom-file">
                    <input
                        type="file"
                        className="custom-file-input"
                        id={id}
                        onChange={() => onChange(fileInput.current.files[0])}
                        ref={fileInput}
                        data-testid={testId}
                    />
                    <label className="custom-file-label" htmlFor={id}>{placeholder}</label>
                </div>
                <div className="invalid-feedback">
                    {errors ? errors.map((error, index) => <div key={index}>{error}</div>) : ''}
                </div>
            </div>
        </div>
    )
};

export default FormUploadFile;
