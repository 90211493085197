import {useCallback, useEffect, useState} from "react";
import HtvApi from "../../htv-api/HtvApi";
import {ErrorHandling} from "../../helpers/error-handling";
import FormUploadFile from "../../layouts/main/components/Form/FormUploadFile";
import Loader from "../../layouts/main/components/Loader";
import PropTypes from "prop-types";
import {Map} from "immutable";
import {NewsImageModel} from "../../models/NewsImage";
import NewsImage from "./NewsImage";
import {FileApi, NewsApi} from "../../HtvApi/Api";

const NewsFormImages = ({images = Map(), onChange}) => {
    const [newsImages, setNewsImages] = useState(images);
    const [addedImages, setAddedImages] = useState(Map());
    const [isLoading, setIsLoading] = useState(true);

    const newsImagesUpdated = useCallback(images => {
        setAddedImages(images);
        onChange(images);
    }, [onChange]);

    useEffect(() => {
        HtvApi.NewsImageSizes.List()
            .then(response => {
                setNewsImages(
                    Map(
                        response['hydra:member']
                            .map(size => newsImages.get(size.label) || size)
                            .map(image => [image.label, new NewsImageModel(image)])
                    )
                )
                setIsLoading(false);
            })
            .catch(ErrorHandling.GlobalStateError);
        // Should not re-run if newsImages are updated. Sizes will not change. Disabling the error check.
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const generateNewsImages = file => {
        FileApi.fileUpload({body: file})
            .then(response => {
                NewsApi.newsGenerateImages({newsGenerateImageRequest: {file: response.data.id}})
                    .then(response => {
                        let generatedImages = Map(
                            response.data.images.map(image => [image.label, new NewsImageModel(image)])
                        );

                        setNewsImages(generatedImages);
                        newsImagesUpdated(generatedImages);
                    })
                    .catch(ErrorHandling.GlobalStateError);
            })
            .catch(ErrorHandling.GlobalStateError);
    }

    const changeGeneratedImage = (label, file) => {
        FileApi.fileUpload({body: file})
            .then(response => {
                NewsApi.newsGenerateImage({label, newsGenerateImageRequest: {file: response.data.id}})
                    .then(response => {
                        let newsImage = new NewsImageModel(response.data);

                        setNewsImages(newsImages.set(label, newsImage));
                        newsImagesUpdated(addedImages.set(label, newsImage));
                    })
                    .catch(ErrorHandling.GlobalStateError);
            })
            .catch(ErrorHandling.GlobalStateError);
    };

    if (isLoading) {
        return <Loader />
    }

    return (
        <>
            <FormUploadFile
                label="Upload for all image sizes"
                placeholder={"Choose image"}
                onChange={value => generateNewsImages(value)}
                testId={'news-form-upload-global-image'}
            />

            <hr />

            {[...newsImages.values()].map(image => {
                const imageComponent = <NewsImage image={image} />;

                const key = image.label + (image.fileID || '-');

                return (
                    <div key={key}>
                        <FormUploadFile
                            label={`Image for ${image.label}`}
                            placeholder={image.fileId || image.url ? 'Change generated image' : 'Choose image'}
                            onChange={file => changeGeneratedImage(image.label, file)}
                        />
                        {imageComponent ? (
                            <div className="row">
                                <div className="col-sm-2" />
                                <div className="col-sm-10">
                                    {imageComponent}
                                </div>
                            </div>
                        ) : ''}
                        <hr />
                    </div>
                )
            })}
        </>
    )
}

NewsFormImages.propTypes = {
    images: PropTypes.instanceOf(Map),
    onChange: PropTypes.func.isRequired,
};

export default NewsFormImages;
