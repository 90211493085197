import {useEffect, useState} from "react";
import HtvApi from "../../htv-api/HtvApi";
import FormSelectMultiple from "../../layouts/main/components/Form/FormSelectMultiple";

const mapCategories = (categories, parent = null) => {
    let mappedCategories = [];

    categories.forEach(category => {
        const categoryParent = category?.parent?.id || null;

        if (categoryParent !== parent) {
            return;
        }

        mappedCategories.push({value: category.id, label: category.title});

        const children = mapCategories(categories, category.id);

        children.forEach(category => {
            mappedCategories.push({value: category.value, label: '|\u00A0\u00A0\u00A0\u00A0' + category.label});
        })
    })

    return mappedCategories;
}

const VideoCategoriesSelect = ({label, selected = [], onChange}) => {
    const [allCategories, setAllCategories] = useState([]);

    useEffect(() => {
        HtvApi.VideoCategories.List().then(response => {
            const categories = mapCategories(response['hydra:member']);
            setAllCategories(categories);
        });
    }, []);

    return (
        <FormSelectMultiple
            label={label}
            options={allCategories}
            selected={selected}
            onChange={values => onChange(values)}
        />
    );
};

export default VideoCategoriesSelect;