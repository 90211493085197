import {Autocomplete, CircularProgress, TextField} from "@mui/material";
import {useEffect, useMemo, useRef, useState} from "react";
import debounce from "lodash/debounce";
import {Record} from "immutable";
import {NewsApi} from "../../../HtvApi/Api";

const RecommendedNewsPicker = ({newsId = null, onChange}) => {
    const [state, setState] = useState(Record({
        open: false,
        options: [],
        value: null,
        inputValue: '',
        loading: true,
        error: false,
        helperText: ' ',
    })());
    const currentId = useRef(newsId);

    const fetchNewsFn = useMemo(() => debounce(
        (title) => {
            NewsApi.newsGetCollection({page: 1, perPage: 10, orderBy: 'createdAt', orderDir: 'desc', searchTitle: title})
                .then(results => {
                    setState(state => state.set('options', results.data['hydra:member']).set('loading', false));
                })
                .catch(error => {
                    setState(
                        state => state
                            .set('open', false)
                            .set('loading', false)
                            .set('error', true)
                            .set('helperText', error.title || error.message)
                    );
                })
        },
        500,
        {trailing: true}
    ), []);

    const onClose = () => {
        fetchNewsFn.cancel();
        setState(state =>
            state
                .set('open', false)
                .set('options', [])
                .set('loading', false)
                .set('error', false)
                .set('helperText', state?.value?.id  ? 'ID: ' + state.value.id : ' ')
        );
    }

    const inputValueChange = value => {
        if (!state.open || value.length < 2) {
            fetchNewsFn.cancel();
            setState(state =>
                state
                    .set('inputValue', value)
                    .set('options', [])
                    .set('loading', false)
                    .set('error', false)
                    .set('helperText', state?.value?.id  ? 'ID: ' + state.value.id : ' ')
            )
            return;
        }

        setState(state =>
            state
                .set('inputValue', value)
                .set('loading', true)
                .set('error', false)
                .set('helperText', ' ')
        );
        fetchNewsFn(value);
    };

    useEffect(() => {
        if (currentId.current === null) {
            setState(state => state.set('loading', false));
            return;
        }

        NewsApi.newsGet({id: currentId.current}).then(response => {
            setState(state =>
                state
                    .set('value', response.data)
                    .set('inputValue', response.data.title)
                    .set('helperText', 'ID: ' + response.data.id)
                    .set('loading', false)
            );
        }).catch(error => {
            setState(state =>
                state
                    .set('error', true)
                    .set('helperText', error.title || error.message)
                    .set('loading', false)
            );
        })
    }, [newsId])

    return <Autocomplete
        open={state.open}
        onOpen={() => setState(state => state.set('open', true))}
        onClose={onClose}
        inputValue={state.inputValue}
        onInputChange={(event, value) => inputValueChange(value)}
        value={state.value}
        onChange={(event, value) => {
            if (!value?.id) {
                setState(state => state.set('helperText', 'ID: ' + currentId.current));
                return;
            }
            currentId.current = value['id'];
            setState(state => state.set('value', value).set('helperText', 'ID: ' + value?.id));
            onChange(currentId.current);
        }}
        isOptionEqualToValue={(option, value) => option.title === value.title}
        renderInput={
            (params) =>
                <TextField
                    {...params}
                    label={`Recommended news`}
                    error={state.error}
                    helperText={state.helperText}
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: <>
                            {state.loading ? <CircularProgress color="inherit" size={20} /> : null }
                            {params.InputProps.endAdornment}
                        </>,
                        'data-testid': 'recommended-news-picker-wrapper',
                    }}
                />
        }
        options={state.options}
        getOptionLabel={option => option.title || state.value.title}
        loading={state.loading}
        noOptionsText={'No matching news found'}
        freeSolo={true}
        selectOnFocus={true}
        handleHomeEndKeys={false}
        clearOnBlur={true}
    />
}

export default RecommendedNewsPicker;
