import WithMainLayoutPage from "../../WithMainLayoutPage";
import PageCard from "layouts/main/components/PageCard";
import {Link, useNavigate} from "react-router-dom";
import {useEffect, useState} from "react";
import {GlobalState} from "state/GlobalState";
import {ErrorHandling} from "helpers/error-handling";
import Loader from "layouts/main/components/Loader";
import NewsForm from "../NewsForm";
import {NewsApi} from "HtvApi/Api";

const NewsCreatePage = () => {
    useEffect(() => {
        GlobalState.setPageTitle('News management');
        GlobalState.setLayoutPageTitle('News management');
    }, []);

    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);

    const onSubmit = formData => {
        setIsLoading(true);

        for (let image of formData.images.values()) {
            if (!image.fileID) {
                ErrorHandling.GlobalStateError(new Error('Invalid or missing image'));
                return;
            }
        }

        let newsData = {
            ...formData,
            images: [
                ...formData.images
                    .filter(image => image.url === undefined)
                    .map(image => image.toImageRequest())
                    .values()
            ],
        }

        NewsApi.newsCreate({newsNewsCreate: newsData})
            .then(response => {
                GlobalState.setSuccess('Successful operation!', 'News "' + response.data.title + '" created successfully!');
                navigate('/news', {state: {keepMessages: true}});
            })
            .catch(error => {
                ErrorHandling.GlobalStateError(error);
                setIsLoading(false);
            });
    };

    return (
        <PageCard
            title='Create news'
            actions={(
                <Link to="/news" className="btn btn-sm btn-danger">
                    <i className="fas fa-arrow-left" />&nbsp;&nbsp;&nbsp;Back to news list
                </Link>
            )}
        >
            {isLoading ? <Loader /> : <NewsForm onSubmit={onSubmit} />}
        </PageCard>
    );
};

export default WithMainLayoutPage(NewsCreatePage);
