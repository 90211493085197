import {useEffect, useState} from "react";
import HtvApi from "../../htv-api/HtvApi";
import FormSelectMultiple from "../../layouts/main/components/Form/FormSelectMultiple";
import Loader from "../../layouts/main/components/Loader";

const NewsFormCategories = ({selected = [], onChange}) => {
    const [allCategories, setAllCategories] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        HtvApi.NewsCategories.List().then(response => {
            setAllCategories(
                response['hydra:member'].map(category => ({value: category['@id'], label: category.title}))
            );
            setIsLoading(false);
        });
    }, []);

    if (isLoading) {
        return <Loader />
    }

    return <FormSelectMultiple
        label="Categories"
        options={allCategories}
        selected={selected}
        onChange={values => onChange(values)}
        testId={'news-form-categories-select'}
    />
};

export default NewsFormCategories;
