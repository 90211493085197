import {observer} from "mobx-react";
import {useEffect, useState} from "react";
import LoadVideoForm from "./LoadVideoForm";
import ResolvedVideoTile from "./ResolvedVideoTile";
import AddVideoForm from "./AddVideoForm";
import WithMainLayoutPage from "../../WithMainLayoutPage";
import {GlobalState} from "../../../state/GlobalState";

const VideoAddPage = observer( (props) => {
    console.log('Rendering VideoAddPage...');
    console.log('Props', props);

    const [resolvedVideo, setResolvedVideo] = useState(null);

    useEffect(() => {
        GlobalState.setPageTitle('Add new video');
        GlobalState.setLayoutPageTitle('Add new video');
    }, []);

    const onVideoResolve = (videoData) => {
        setResolvedVideo(videoData);
    }

    const onVideoCancelResolve = () => {
        setResolvedVideo(null);
    }

    if (resolvedVideo) {
        return (
            <>
                <ResolvedVideoTile videoData={resolvedVideo} cancelResolve={onVideoCancelResolve} />
                <AddVideoForm videoData={resolvedVideo} />
            </>
        )
    }

    return (
        <LoadVideoForm onVideoResolve={onVideoResolve} />
    );
})

export default WithMainLayoutPage(VideoAddPage);
