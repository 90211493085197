const Loader = ({small}) => {
    return (
        <div className="overlay dark loader">
            <i className="fas fa-3x fa-sync-alt fa-spin" />
            {small ? '' : <div className="text-bold pt-2">Loading...</div>}
        </div>
    );
};

export default Loader;
