import {observer} from "mobx-react";
import {AuthenticationState} from "../../state/AuthenticationState";
import {Navigate, useLocation} from "react-router-dom";

const RequireAuthorization = observer(({children}) => {
    let location = useLocation();

    if (!AuthenticationState.isAuthenticated) {
        return <Navigate to={"/login"} state={{redirectTo: location.pathname + location.search + location.hash}} />
    }

    return children;
});

export default RequireAuthorization;