import {Button, Modal} from "react-bootstrap";
import Locales from "../../Locales";
import dayjs from "dayjs";
import {useState} from "react";
import Loader from "../../layouts/main/components/Loader";
import {ErrorHandling} from "../../helpers/error-handling";
import {NewsApi} from "../../HtvApi/Api";

const NewsInformationDialogButton = ({newsId}) => {
    const [displayModal, setDisplayModal] = useState(false);
    const [newsData, setNewsData] = useState({});

    const displayNewsData = () => {
        setDisplayModal(true);

        NewsApi.newsGet({id: newsId})
            .then(response => setNewsData(response.data))
            .catch((error) => {
                ErrorHandling.GlobalStateError(error);
                setDisplayModal(false);
            });
    }

    const informationButton = (
        <button
            className="btn btn-info btn-sm"
            onClick={() => displayNewsData()}
            data-testid={`news-info-button-${newsId}`}
        >
            <i className="fas fa-info-circle" /> Info
        </button>
    );

    if (!Object.prototype.hasOwnProperty.call(newsData, 'id')) {
        return (
            <>
                {informationButton}
                <Modal
                    show={displayModal}
                    onHide={() => setDisplayModal(false)}
                    size="xl"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <Loader />
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">Loading news information...</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => setDisplayModal(false)} className="mr-auto">
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>
            </>
        );
    }

    return (
        <>
            {informationButton}
            <Modal
                show={displayModal}
                onHide={() => setDisplayModal(false)}
                size="xl"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">News information</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <dl className="row">
                        <dt className="col-sm-4">ID:</dt>
                        <dd className="col-sm-8">{newsData['id']}</dd>

                        <dt className="col-sm-4">Title:</dt>
                        <dd className="col-sm-8">{newsData['titleTranslations'].map(titleTranslation => {
                            return (
                                <span key={titleTranslation['locale']}>
                                    <strong>{Locales[titleTranslation['locale']] + ': '}</strong> {titleTranslation['value']}
                                    <br />
                                </span>
                            );
                        })}</dd>

                        <dt className="col-sm-4">Sub title:</dt>
                        <dd className="col-sm-8">{newsData['subTitleTranslations'].map(subTitleTranslation => {
                            return (
                                <span key={subTitleTranslation['locale']}>
                                    <strong>{Locales[subTitleTranslation['locale']] + ': '}</strong> {subTitleTranslation['value']}
                                    <br />
                                </span>
                            );
                        })}</dd>

                        <dt className="col-sm-4">Content:</dt>
                        <dd className="col-sm-8">{newsData['contentTranslations'].map(contentTranslation => {
                            return (
                                <span key={contentTranslation['locale']}>
                                    <strong>{Locales[contentTranslation['locale']] + ': '}</strong> {contentTranslation['value']}
                                    <br />
                                </span>
                            );
                        })}</dd>

                        <dt className="col-sm-4">Slug (Link):</dt>
                        <dd className="col-sm-8">{newsData['slug']}</dd>

                        <dt className="col-sm-4">Source:</dt>
                        <dd className="col-sm-8">{newsData['source']}</dd>

                        <dt className="col-sm-4">Display date:</dt>
                        <dd className="col-sm-8">
                            {dayjs(newsData['displayDate']).format('D MMM YYYY H:mm:ss')}
                        </dd>

                        <dt className="col-sm-4">Created at:</dt>
                        <dd className="col-sm-8">
                            {dayjs(newsData['createdAt']).format('D MMM YYYY H:mm:ss')}
                        </dd>

                        <dt className="col-sm-4">Created by:</dt>
                        <dd className="col-sm-8">{newsData['createdBy']['firstName']} {newsData['createdBy']['lastName']}</dd>

                        <dt className="col-sm-4">Updated at:</dt>
                        <dd className="col-sm-8">
                            {newsData['updatedAt'] ? dayjs(newsData['updatedAt']).format('D MMM YYYY H:mm:ss') : '-'}
                        </dd>

                        <dt className="col-sm-4">Updated by:</dt>
                        <dd className="col-sm-8">
                            {
                                newsData['updatedBy']
                                    ? newsData['updatedBy']['firstName'] + ' ' + newsData['updatedBy']['lastName']
                                    : '-'
                            }
                        </dd>

                        <dt className="col-sm-4">Status:</dt>
                        <dd className="col-sm-8">{newsData['status']}</dd>

                        {newsData['vimeoVideo']
                            ? <>
                                <dt className="col-sm-4">Vimeo video:</dt>
                                <dd className="col-sm-8">
                                    <iframe
                                        src={'https://player.vimeo.com/video/' + newsData['vimeoVideo']}
                                        title={'Video attached to news'}
                                        width="640"
                                        height="360"
                                        frameBorder="0" allow="autoplay; fullscreen; picture-in-picture"
                                        allowFullScreen
                                    />
                                </dd>
                            </>
                            : ''
                        }

                        <dt className="col-sm-4">Read count:</dt>
                        <dd className="col-sm-8">{newsData['readCount']}</dd>

                        <dt className="col-sm-4">Categories:</dt>
                        <dd className="col-sm-8">
                            <ul>
                                {newsData['categories'].map(category => (<li key={category['id']}>{category['title']}</li>))}
                            </ul>
                        </dd>

                        <dt className="col-sm-4">Images:</dt>
                        <dd className="col-sm-8">
                            {newsData['images'].map(imageInfo => (
                                <div key={imageInfo.label}>
                                    <p className="lead">{imageInfo.label}</p>
                                    <img
                                        src={imageInfo.url}
                                        alt="News cover"
                                        data-testid={`news-image-${imageInfo.label}`}
                                    />
                                </div>
                            ))}
                        </dd>
                    </dl>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setDisplayModal(false)} className="mr-auto">
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
};

export default NewsInformationDialogButton;
