import {useEffect, useState} from "react";
import {ErrorHandling} from "../../helpers/error-handling";
import {useNavigate} from "react-router-dom";
import Sortable from "../../layouts/main/components/Sortable/Sortable";
import BannerSortableItem from "./BannerSortableItem";
import {BannerApi} from "../../HtvApi/Api";

const BannersList = ({zoneData, onBannerPositionsChange}) => {
    const navigate = useNavigate();
    const [banners, setBanners] = useState([]);
    const [version, setVersion] = useState(1);

    useEffect(() => {
        BannerApi.bannerGetForBannerZone({bannerZoneId: zoneData.id})
            .then(response => {
                setBanners(response.data['hydra:member']);
                onBannerPositionsChange(response.data['hydra:member'].map(banner => banner['@id']));
            })
            .catch(error => {
                ErrorHandling.GlobalStateError(error);
                navigate('/banners', {state: {keepMessages: true}});
            })
    }, [zoneData, navigate, onBannerPositionsChange, version]);

    const onSortChange = items => {
        onBannerPositionsChange(items.map(banner => banner['@id']));
    }

    return <table className="table table-hover text-nowrap" id="banners-list">
        <thead>
            <tr>
                <th>Image</th>
                <th>Link</th>
                <th>Status</th>
                <th>Last change</th>
                <th>Options</th>
            </tr>
        </thead>
        <tbody>{banners.length > 0
            ? <Sortable
                items={banners}
                itemRenderFn={
                    banner => <BannerSortableItem
                        banner={banner}
                        key={banner.id}
                        id={'banner-' + banner.id}
                        onDelete={() => setVersion(version => version + 1)}
                    />
                }
                onChange={onSortChange}
            />
            : <tr><td colSpan={5}>No results</td></tr>
        }
        </tbody>
    </table>;
};

export default  BannersList;
